import { Box, Input } from '@mui/joy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '~/common/types';

import { containsOnlyDigits, removeSubstrings } from '~/common/utils/utils';
import useValidation, {
  CODE_BELGIUM,
  FORBIDDEN_CHARS,
  LENGTH_BELGIAN_NUMBER,
  PREFIX_LENGTH,
} from './useValidation';
import { useGetEnums } from '@/queries';

import SelectCountry, { CountryOption } from '../../2.0/select/SelectCountry';

export type VatPayload = {
  value: Nullable<string>;
  valid: Nullable<boolean>;
  error: string | undefined;
  companyName: Nullable<string>;
};

type Props = {
  value?: string;
  defaultValue?: string;
  error?: string;
  required?: boolean;
  onChange: (payload: VatPayload) => void;
  onBlur?: () => void;
};

const splitValue = (value?: string) => ({
  countryCode: value?.substring(0, PREFIX_LENGTH) || CODE_BELGIUM,
  vatNumber: value?.substring(PREFIX_LENGTH) || null,
});

const VatNumberInput: React.FC<Props> = ({
  value,
  defaultValue,
  error,
  required = false,
  onChange = () => {},
  onBlur = () => {},
}) => {
  const initialValues = splitValue(value);

  const [countryCode, setCountryCode] = useState<Nullable<string>>(initialValues.countryCode);
  const [vatNumber, setVatNumber] = useState<Nullable<string>>(initialValues.vatNumber);

  const { t } = useTranslation();
  const { validationResult, validateInput } = useValidation(
    { countryCode, vatNumber },
    { required },
  );
  const {
    enums: { countries },
  } = useGetEnums();

  const { validAgainstCompany, validAgainstSchema, errors, foundCompany } = validationResult;

  const countryCodes = useMemo(
    () => (countries as CountryOption[]).map(({ code }) => code),
    [countries],
  );

  const cleanUpInput = useCallback(
    (number: string) => {
      let cleanedNumber = removeSubstrings(number, FORBIDDEN_CHARS);

      const isBelgian = countryCode === CODE_BELGIUM;
      const twoFirstChars = cleanedNumber.substring(0, PREFIX_LENGTH).toLocaleUpperCase();

      if (countryCodes.includes(twoFirstChars) && twoFirstChars !== countryCode) {
        setCountryCode(twoFirstChars);

        cleanedNumber = cleanedNumber.substring(PREFIX_LENGTH);
      }
      if (isBelgian && cleanedNumber && !containsOnlyDigits(cleanedNumber)) {
        const nonDigits = /\D/g;

        cleanedNumber = cleanedNumber.replace(nonDigits, '');
      }
      if (isBelgian && cleanedNumber.length > LENGTH_BELGIAN_NUMBER) {
        cleanedNumber = cleanedNumber.substring(0, LENGTH_BELGIAN_NUMBER);
      }

      if (cleanedNumber !== number) {
        setVatNumber(cleanedNumber);
      }
    },
    [countryCodes, countryCode],
  );

  useEffect(() => {
    if (!vatNumber) {
      return;
    }

    cleanUpInput(vatNumber);
  }, [vatNumber, cleanUpInput]);

  const internalError = errors.at(-1)?.message;
  const valid = validAgainstSchema && validAgainstCompany;

  useEffect(() => {
    let combinedNumber: Nullable<string> = null;

    if (countryCode && vatNumber) {
      combinedNumber = countryCode.concat(vatNumber);
    }

    onChange({
      companyName: foundCompany,
      error: internalError,
      valid,
      value: combinedNumber,
    });
  }, [countryCode, vatNumber, valid, internalError, foundCompany]);

  useEffect(() => {
    // for overwriting from outside only (currently the case in mandateForm)
    // passing to formik initalValues is preferred, but not always possible
    if (!defaultValue) {
      return;
    }

    const newValues = splitValue(defaultValue);

    setCountryCode(newValues.countryCode);
    setVatNumber(newValues.vatNumber);
  }, [defaultValue]);

  const message = (() => {
    if (error) {
      return error;
    }

    return internalError || foundCompany;
  })();

  const color = (() => {
    if (!error && valid) {
      return 'success';
    }
    if (!error && validAgainstSchema && validAgainstCompany === null) {
      return 'neutral';
    }

    return 'danger';
  })();

  return (
    <div>
      <div className="flex">
        <SelectCountry
          value={countryCode}
          onChange={(code) => setCountryCode(code)}
          className="w-32 rounded-e-none border-r-0"
          color={color}
          onBlur={() => validateInput()}
          fullNames={false}
        />
        <Input
          className="rounded-s-none"
          placeholder={t('vatNumber')}
          fullWidth
          value={vatNumber || ''}
          onChange={(e) => setVatNumber(e.target.value || null)}
          color={color}
          onBlur={() => {
            validateInput();
            onBlur();
          }}
        />
      </div>
      <Box
        sx={(theme) => ({
          color: theme.palette[color][500],
        })}
      >
        {!!message && <p className="mt-1.5 pl-1 text-xs text-inherit">{message}</p>}
      </Box>
    </div>
  );
};

export default VatNumberInput;
