import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AddressCustomCell from '@/shared/components/2.0/customTableCells/AddressCustomCell';
import DataTable from '@/shared/components/2.0/DataTable';
import EnumValueCell from '@/shared/components/2.0/customTableCells/EnumValueCell';

import RelationContactInfoCustomCell from './CustomCells/RelationContactInfoCustomCell';
import RelationCustomActionCell from './CustomCells/RelationCustomActionCell';
import RelationDetailRow from './CustomCells/RelationDetailRow';
import RelationNameCustomCell from './CustomCells/RelationNameCustomCell';
import RelationRoleCustomCell from './CustomCells/RelationRoleCustomCell';
import useGetSimpleBuildingRelations from '@/queries/buildings/useGetSimpleBuildingRelations';

import { decapitalize, getRelationsWithSubordinates } from '@/shared/utils/helpers';
import { CaseContext } from '@/containers/Cases/CaseContext';
import { CaseRelation } from '@/types/cases';
import EntityType from '@/shared/enums/EntityType';
import { localStorageKeys } from '@/shared/utils/constants';
import { Nullable } from '~/common/types';
import { UseToggle } from '@/shared/hooks/UseToggle';

type Props = {
  caseId: string;
  caseReference: string;
  caseRelations: CaseRelation[];
  canEdit: boolean;
  currentTeamIsOwner: boolean;
  editToggle: Nullable<UseToggle<any>>;
};

const useTableConfig = () => {
  const { t } = useTranslation();
  return {
    columns: {
      localStorageKey: localStorageKeys.caseRelations.COLUMNS,
      list: [
        {
          allowDeselect: true,
          customCellComponent: RelationRoleCustomCell,
          key: 'role',
          title: t('role'),
          width: 140,
        },
        {
          allowDeselect: true,
          customCellComponent: RelationNameCustomCell,
          key: 'name',
          title: t('name'),
          width: 'auto',
        },
        {
          allowDeselect: true,
          customCellComponent: RelationContactInfoCustomCell,
          key: 'contact',
          title: t('contact'),
          width: 'auto',
        },
        {
          allowDeselect: true,
          customCellComponent: AddressCustomCell,
          key: 'address',
          title: t('_address.label'),
          width: 'auto',
        },
        {
          allowDeselect: true,
          customCellComponent: EnumValueCell,
          key: 'involvementType',
          title: t('involvementType'),
          width: 'auto',
        },
        {
          allowDeselect: false,
          customCellComponent: RelationCustomActionCell,
          key: 'id',
          width: 150,
        },
      ],
    },
    rowKeyField: 'id',
    type: decapitalize(EntityType.CaseRelation as string),
  };
};

const CaseRelationsOverview: React.FC<Props> = ({ editToggle }) => {
  const { currentCase, canEditCase, ownRelation } = useContext(CaseContext);
  const { relations } = useGetSimpleBuildingRelations(currentCase.building?.buildingId);
  const caseRelations = currentCase.relations;
  const currentTeamIsOwner = ownRelation.isOwner;
  const currentTeamIsRequestor = ownRelation.isRequestor;

  const tableConfig = useTableConfig();
  const { t } = useTranslation();

  const data = useMemo(() => {
    const mappedRelations = caseRelations.map((relation) => {
      const {
        relationAddressAddressLine,
        relationAddressCity,
        relationAddressCountry,
        relationAddressPostalCode,
        ...rest
      } = relation;
      return {
        ...rest,
        address: {
          addressLine: relationAddressAddressLine,
          city: relationAddressCity,
          country: relationAddressCountry,
          postalCode: relationAddressPostalCode,
        },
        canEdit: canEditCase,
        caseId: currentCase.id,
        caseReference: currentCase.reference,
        currentTeamIsOwner,
        currentTeamIsRequestor,
        isInsuranceClaimCase: currentCase.isInsuranceClaimCase,
        numberOfClientRelations: caseRelations?.filter((rel) => rel.isClient)?.length ?? 0,
        unitLinks: relations.filter(
          (rel) => rel.contactId === relation.contactId && rel.companyId === relation.companyId,
        )[0]?.units,
      };
    });

    return getRelationsWithSubordinates(mappedRelations);
  }, [
    currentCase,
    canEditCase,
    caseRelations,
    currentTeamIsOwner,
    currentTeamIsRequestor,
    relations,
  ]);

  return (
    <div>
      <DataTable
        title={t('involvedParties')}
        config={tableConfig}
        data={data}
        rowId="id"
        defaultActions={{
          columnsSelection: true,
          create: canEditCase,
          delete: false,
        }}
        toggles={{
          create: editToggle,
          customEdit: editToggle,
        }}
        childComponents={{
          detailsRow: {
            content: RelationDetailRow,
          },
        }}
      />
    </div>
  );
};

export default CaseRelationsOverview;
