import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import FormikAutocomplete from '../../2.0/formik/FormikAutocomplete';
import FormikTextField from '../../2.0/formik/FormikTextField';
import useDocumentTypeOptions from '@/shared/hooks/useDocumentTypeOptions';

const DocumentUpdateForm = () => {
  const { t } = useTranslation();
  const documentTypeSuggestions = useDocumentTypeOptions();
  const ref = useRef();

  ref.current?.focus();

  return (
    <div className="py-4">
      <FormikAutocomplete
        freeSolo
        name="type"
        options={documentTypeSuggestions}
        label={t('type')}
      />
      {/* @ts-ignore */}
      <FormikTextField ref={ref} name="fileName" label={t('fileName')} />
    </div>
  );
};

export default DocumentUpdateForm;
