import * as yup from 'yup';
import dayjs from 'dayjs';
import { sumBy } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import BilledStatus from '~/common/enums/billedStatus';
import { Performance } from '@/types/trpc/hoa';

import { DivisionType, Unity, VatCode } from '~/common/enums';
import { Nullable } from '~/common/types/index';
import { ZeroVat } from '~/common/enums/vatCode';

export type PerformanceShare = {
  unitId: string;
  buildingRelationId: Nullable<string>;
  share: number;
};

export type UnitRelationShare = {
  buildingRelationId: string;
  key: string;
  unitId: string;
};

export type IndividualDivision = {
  amount: number;
  buildingRelationId: string;
  percentage: string | null;
  share: number;
  unitRelationShare: UnitRelationShare;
};

const PerformanceVatCodes = { ...VatCode, ...ZeroVat };
export type PerformanceVatCode = keyof typeof PerformanceVatCodes;

export type PerformanceFormFields = {
  amount: number;
  bookYearId: string | null;
  contractId: string | null;
  contractPerformanceId: string | null;
  date: string;
  description: string | null;
  divisionKeyId: string | null;
  divisionType: DivisionType | null;
  homeownerAssociationGeneralLedgerAccountId: string | null;
  homeownerAssociationId: string | null;
  individualDivisions?: IndividualDivision[];
  shares: PerformanceShare[];
  status: BilledStatus | null;
  total: number | null;
  unitPrice: number | null;
  unity: Unity;
  vat: PerformanceVatCode;
};

const usePerformanceFormikConfig = (initialPerformance?: Performance | null) => {
  // also used in toolbar actions, so no guarantee this will be defined
  const { homeownerAssociationId } = useParams();
  const { t } = useTranslation('errors');

  const initialValues = useMemo(() => {
    const defaultInitialValues = {
      amount: 1,
      bookYearId: null,
      contractId: null,
      contractPerformanceId: null,
      date: dayjs().format('YYYY-MM-DD'),
      description: null,
      divisionKeyId: null,
      divisionType: DivisionType.Amount,
      homeownerAssociationGeneralLedgerAccountId: null,
      homeownerAssociationId: homeownerAssociationId || null,
      individualDivisions: [],
      shares: [],
      status: BilledStatus.NotBilled,
      total: null,
      unitPrice: null,
      unity: Unity.Piece,
      vat: VatCode.InlandExclusive21,
    };

    if (!initialPerformance) return defaultInitialValues;

    const initialIndividualDivisions = initialPerformance.shares.map(
      ({ buildingRelationId, share, unitId }) => {
        const amount = (initialPerformance.unitPrice * initialPerformance.amount) / (100 / share);

        return {
          amount,
          buildingRelationId,
          percentage: null,
          share,
          unitRelationShare: {
            buildingRelationId,
            key: `${buildingRelationId}-${unitId}`,
            unitId,
          },
        };
      },
    );

    return {
      ...defaultInitialValues,
      ...initialPerformance,
      individualDivisions: initialIndividualDivisions,
      total: initialPerformance.totalPrice,
      unity: initialPerformance.unity || Unity.Piece,
      vat: initialPerformance.vat || VatCode.InlandExclusive21,
    };
  }, [homeownerAssociationId, initialPerformance]);

  const validationSchema = yup.object().shape(
    {
      amount: yup.number().nullable().required(t('fieldIsRequired')).min(0, 'valueMustBePositive'),
      bookYearId: yup.string().nullable().required(t('fieldIsRequired')),
      contractId: yup.string().nullable().required(t('fieldIsRequired')),
      contractPerformanceId: yup.string().nullable().required(t('fieldIsRequired')),
      date: yup.string().nullable().required(t('fieldIsRequired')),
      description: yup.string().nullable().required(t('fieldIsRequired')),
      divisionKeyId: yup
        .string()
        .nullable()
        .test('fieldIsInvalid', t('fieldIsInvalid'), (value, ctx) => {
          if (value && !ctx.parent.individualDivisions.length) return true;

          return (
            sumBy(ctx.parent.individualDivisions, (division: IndividualDivision) =>
              division.percentage ? parseFloat(division.percentage) || 0 : 0,
            ) === 100
          );
        }),
      homeownerAssociationGeneralLedgerAccountId: yup.string().nullable(),
      homeownerAssociationId: yup.string().nullable().required(t('fieldIsRequired')),
      total: yup.string().required(t('fieldIsRequired')),
      unitPrice: yup.string().nullable().required(t('fieldIsRequired')),
      unity: yup.string().nullable().required(t('fieldIsRequired')),
      vat: yup.string().nullable().required(t('fieldIsRequired')),
    },
    [['useDefaultSavingsAccount', 'useDefaultCurrentAccount']],
  );

  return {
    initialValues,
    validationSchema,
  };
};

export default usePerformanceFormikConfig;
