import { useQueryClient } from '@tanstack/react-query';
import { trpc } from '@/config/trpc';
import { queryIds } from '@/shared/utils/constants';

const useCreateCompany = () => {
  const queryClient = useQueryClient();
  const utils = trpc.useUtils();

  return trpc.company.create.useMutation({
    onSuccess: () => {
      utils.company.list.invalidate();
      queryClient.invalidateQueries([queryIds.companies.GET_COMPANIES]);
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === queryIds.crm.GET_OPTIONS,
      });
    },
  });
};

export default useCreateCompany;
