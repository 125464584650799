import { useEffect, useMemo } from 'react';

import {
  AutocompleteOption,
  SelectionOptions,
  SharedOptions,
  UseAutocompleteOptionsResult,
} from './types';
import { Obj } from '~/common/types';

type BaseOptions<V extends string | number, P extends Obj> = SharedOptions & {
  data?: AutocompleteOption<V, P>[];
  isLoading?: boolean;
};

type Options<V extends string | number, P extends Obj> = BaseOptions<V, P> & SelectionOptions<V, P>;

// regular functions for function overload -> dynamic ouput without union type based on input
function useAutocompleteOptions<Value extends string | number, AdditionalProperties extends Obj>(
  options: Options<Value, AdditionalProperties> & { multiple: true },
): UseAutocompleteOptionsResult<Value, true, AdditionalProperties>;

function useAutocompleteOptions<Value extends string | number, AdditionalProperties extends Obj>(
  options: Options<Value, AdditionalProperties> & { multiple?: false },
): UseAutocompleteOptionsResult<Value, false | undefined, AdditionalProperties>;

function useAutocompleteOptions<Value extends string | number, AdditionalProperties extends Obj>(
  options: Options<Value, AdditionalProperties> & { multiple?: boolean },
): UseAutocompleteOptionsResult<Value, boolean | undefined, AdditionalProperties>;

function useAutocompleteOptions<Value extends string | number, AdditionalProperties extends Obj>({
  callback,
  data,
  disableClearable = false,
  isLoading = false,
  multiple,
  selected,
}: Options<Value, AdditionalProperties>): UseAutocompleteOptionsResult<
  Value,
  typeof multiple,
  AdditionalProperties
> {
  const value = useMemo(() => {
    if (multiple) {
      return data?.filter((option) => selected?.includes(option.value)) || [];
    }

    return data?.find((option) => option.value === selected) || null;
  }, [data, multiple, selected]);

  useEffect(() => {
    if (value || data?.length !== 1) return;

    if (multiple) {
      callback(data || []);
    } else {
      callback(data?.[0] || null);
    }
  }, [callback, data, multiple, value]);

  return {
    disableClearable,
    disabled: data?.length === 1,
    loading: isLoading,
    multiple,
    onChange: (_, newValue) => {
      if (multiple) {
        callback(newValue as AutocompleteOption<Value, AdditionalProperties>[]);
      } else {
        callback(newValue as AutocompleteOption<Value, AdditionalProperties> | null);
      }
    },
    options: data || [],
    value,
  };
}

export default useAutocompleteOptions;
