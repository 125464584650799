import { Autocomplete, Skeleton, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetSelectorCases } from '../../../../queries';

const propTypes = {
  callback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  name: PropTypes.string,
};

const CaseFilter = ({ callback = () => {}, name = '', defaultValue = [], label = null }) => {
  const { t } = useTranslation('common');
  const getOptions = useCallback((value) => value.reference, []);

  const { selectorCases, selectorCasesIsLoading } = useGetSelectorCases({ inactive: false });

  return selectorCasesIsLoading ? (
    <Skeleton variant="text" height={25} />
  ) : (
    <Autocomplete
      noOptionsText={t('common:noOptions')}
      multiple
      options={selectorCases}
      isOptionEqualToValue={(opt, val) => opt.id === val.id}
      fullWidth
      value={defaultValue}
      onChange={(e, values) => callback(name, values)}
      disableCloseOnSelect
      filterSelectedOptions
      getOptionLabel={getOptions}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label ?? t('common:cases')} />
      )}
    />
  );
};

CaseFilter.propTypes = propTypes;

export default CaseFilter;
