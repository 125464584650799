import * as ibantools from 'ibantools';
import * as yup from 'yup';
import { round, sumBy } from 'lodash';
import { Dayjs } from 'dayjs';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { AutocompleteOption, Nullable } from '~/common/types';
import {
  BicValue,
  DivisionType,
  InboundHoaInvoiceType,
  InboundHoaInvoiceVatCode,
  PaymentMethod,
} from '~/common/enums';
import { InboundHoaInvoice } from '~/common/types/finance/inbound/hoa/inboundHoaInvoices';

import { formatAsCurrency, removeWhitespaces } from '@/shared/utils/helpers';
import {
  isStructuredReference,
  isStructuredReferenceValid,
} from '@/containers/Cases/FinancialManagement/utils';

import LineItems from '@/containers/Cases/FinancialManagement/Components/LineItems';

export type ShareValues = {
  amount: Nullable<number>;
  buildingRelationId: Nullable<string>;
  percentage: Nullable<number>;
  share: Nullable<number>;
  unitId: Nullable<string>;
};

export type LineItem = {
  description: Nullable<string>;
  accountId: Nullable<string>;
  grossAmount: Nullable<number>;
  divisionKey: Nullable<string>;
  divisionType: Nullable<DivisionType>;
  netAmount: Nullable<number>;
  percentage: Nullable<number>;
  shares: ShareValues[];
  vatAmount: Nullable<number>;
  vatCode: Nullable<InboundHoaInvoiceVatCode>;
  isDefault: boolean;
  date: Nullable<string | Dayjs | Date>;
  dateUntil: Nullable<string | Dayjs | Date>;
};

type OctopusJournalKey = {
  key: string;
  type: InboundHoaInvoiceType;
  draftType: InboundHoaInvoiceType;
  value: string;
};

export type BaseCreateFormValues = {
  bookYearId: Nullable<string>;
  bookYearPeriod: Nullable<number>;
  creditNotePurchaseInvoiceId: Nullable<string>;
  description: Nullable<string>;
  draftType: Nullable<
    | InboundHoaInvoiceType.Draft
    | InboundHoaInvoiceType.DraftCreditNote
    | InboundHoaInvoiceType.DraftPurchaseInvoice
  >;
  grossAmount: Nullable<number>;
  homeownerAssociationId: Nullable<string>;
  invoiceNumber: Nullable<string>;
  isDraft: boolean;
  initialOctopusJournalKey: Nullable<string>;
  meterCode: Nullable<string>;
  paymentMethod: Nullable<PaymentMethod>;
  structuredReference: Nullable<string>;
  supplierAccountNumber: Nullable<string>;
  supplierBic: Nullable<BicValue>;
  supplierCompanyId: Nullable<string>;
  type: Nullable<
    | InboundHoaInvoiceType.CreditNote
    | InboundHoaInvoiceType.PurchaseInvoice
    | InboundHoaInvoiceType.Draft
  >;
  amountToPay: Nullable<number>;
  comment: Nullable<string>;
  document: Nullable<File | Blob>;
  lineItems: LineItem[];
  supplierSelect: Nullable<AutocompleteOption>;
  invoiceDivision: Nullable<string>;
  invoiceDate: Nullable<string | Dayjs | Date>;
  dueDate: Nullable<string | Dayjs | Date>;
  octopusJournalKey: Nullable<OctopusJournalKey>;
};

export type BaseUpdateFormValues = BaseCreateFormValues & {
  invoiceId: string;
};

export const isUpdateFormValues = (value: BaseCreateFormValues): value is BaseUpdateFormValues =>
  !!(value as BaseUpdateFormValues).invoiceId;

export const validateIndividualDivision = (
  lineItem: Pick<LineItem, 'divisionKey' | 'grossAmount' | 'shares'>,
) => {
  const { divisionKey, grossAmount, shares } = lineItem;
  const { t } = i18next;

  const errors: string[] = [];

  if (divisionKey)
    return {
      errors,
      isValid: true,
    };

  const relationsMissing = shares.some((share) => !share.buildingRelationId);

  const sharesTotal = sumBy(shares, (share) => {
    const { amount } = share;

    return amount || 0;
  });

  const totalAmount = grossAmount || 0;
  // can be -0 when working with decimal values, due to the way JS handles floats
  const remainingAmount = Math.abs(totalAmount - sharesTotal);

  if (relationsMissing) {
    errors.push(t('errors:relationsMissing'));
  }
  if (remainingAmount) {
    errors.push(
      t('_inboundHoaInvoice.actions.amountToDivide', {
        amountLeftToDivide: formatAsCurrency(remainingAmount, true),
        totalAmountToDivide: formatAsCurrency(totalAmount, true),
      }),
    );
  }
  if (!totalAmount) {
    errors.push(t('errors:totalAmountMissing'));
  }

  return {
    errors,
    isValid: !errors.length,
  };
};

const DECIMALS = 2;

const useInvoiceValidationFormikContext = (invoice?: InboundHoaInvoice) => {
  const { t } = useTranslation('errors');

  const initialValues = (() => {
    if (invoice) {
      const initialBic = invoice?.supplierCompanyRelation.iban.bic;

      const initialLineItems = invoice.lineItems.length
        ? invoice.lineItems.map((lineItem) => ({
            ...lineItem,
            divisionType: DivisionType.Amount,
            isDefault: false,
            percentage:
              invoice.grossAmount && lineItem.grossAmount
                ? round((lineItem.grossAmount / invoice.grossAmount) * 100, DECIMALS)
                : null,
            shares: lineItem.shares as ShareValues[],
            vatAmount: lineItem.grossAmount || 0 - lineItem.netAmount || 0,
          }))
        : [
            {
              accountId: null,
              date: invoice.invoiceDate || null,
              dateUntil: invoice.invoiceDate || null,
              description: invoice.description || null,
              divisionKey: null,
              divisionType: DivisionType.Amount,
              grossAmount: invoice.grossAmount || 0,
              isDefault: false,
              // not in database just for UI purposes
              netAmount: invoice.grossAmount
                ? round(invoice.grossAmount - (invoice.grossAmount / 121) * 21, DECIMALS)
                : 0,
              percentage: 100,
              shares: [],
              vatAmount: invoice.grossAmount ? invoice.grossAmount * 0.21 : 0,
              vatCode: InboundHoaInvoiceVatCode.PurchaseServices21,
            },
          ];

      const isDraft = [
        InboundHoaInvoiceType.Draft,
        InboundHoaInvoiceType.DraftPurchaseInvoice,
        InboundHoaInvoiceType.DraftCreditNote,
      ].includes(invoice.type);

      return {
        ...invoice,
        amountToPay: invoice.grossAmount,
        bookYearId: invoice.bookYearId,
        bookYearPeriod: invoice.bookYearPeriod,
        comment: invoice.comment,
        creditNotePurchaseInvoiceId: null,
        creditNotePurchaseInvoiceSelect: null,
        document: null,
        draftType: isDraft ? invoice.draftType : null,
        homeownerAssociationId: invoice.homeownerAssociationRelation.homeownerAssociationId,
        initialOctopusJournalKey: invoice.octopusJournalKey,
        invoiceDivision: null,
        invoiceId: invoice.id,
        isDraft,
        lineItems: initialLineItems,
        octopusJournalKey: null,
        paymentMethod: invoice.supplierCompanyRelation.paymentMethod,
        supplierAccountNumber: invoice.supplierCompanyRelation.iban.accountNumber,
        supplierBic:
          !!initialBic && initialBic !== 'Unspecified'
            ? BicValue[initialBic as keyof typeof BicValue]
            : null,
        supplierCompanyId: invoice.supplierCompanyRelation.companyId,
        supplierSelect: invoice.supplierCompanyRelation.companyId
          ? {
              label: invoice.supplierCompanyRelation.name,
              value: invoice.supplierCompanyRelation.companyId,
            }
          : null,
      };
    }

    return {
      amountToPay: null,
      bookYearId: null,
      bookYearPeriod: null,
      comment: null,
      creditNotePurchaseInvoiceId: null,
      description: null,
      document: null,
      draftType: null,
      dueDate: null,
      grossAmount: null,
      homeownerAssociationId: null,
      initialOctopusJournalKey: null,
      invoiceDate: null,
      invoiceDivision: null,
      invoiceNumber: null,
      isDraft: false,
      lineItems: [],
      meterCode: null,
      octopusJournalKey: null,
      paymentMethod: PaymentMethod.WireTransfer,
      structuredReference: null,
      supplierAccountNumber: null,
      supplierBic: null,
      supplierCompanyId: null,
      supplierSelect: null,
      type: null,
    };
  })();

  const requireForFinal = <T extends () => yup.BaseSchema>(schemaBuilder: T): ReturnType<T> =>
    schemaBuilder()
      .nullable()
      .test('requireForFinal', t('fieldIsRequired'), (value: any, context: any) => {
        if (context.parent.isDraft || context.options.context?.isDraft) {
          return true;
        }
        return value != null;
      });

  const requireForNew = <T extends () => yup.BaseSchema>(schemaBuilder: T): ReturnType<T> =>
    schemaBuilder()
      .nullable()
      .when('invoiceId', {
        is: (invoiceId: string | null) => !invoiceId,
        then: schemaBuilder().nullable().required(t('fieldIsRequired')),
      });

  const requireForInvoice = <T extends () => yup.BaseSchema>(schemaBuilder: T): ReturnType<T> =>
    schemaBuilder()
      .nullable()
      .when(['isDraft', 'octopusJournalKey'], {
        is: (isDraft: boolean, octopusJournalKey: OctopusJournalKey | null) =>
          !isDraft && octopusJournalKey?.type !== InboundHoaInvoiceType.CreditNote,
        then: schemaBuilder().nullable().required(t('fieldIsRequired')),
      });

  const grossAmountSchema = requireForFinal(yup.number)
    .transform((value) => (Number.isNaN(value) ? null : value))
    .test('maxAmountForLinkedPurchaseInvoice', '', (value, { parent, createError }) => {
      if (
        !parent.creditNotePurchaseInvoiceSelect ||
        parent.octopusJournalKey?.type !== InboundHoaInvoiceType.CreditNote
      ) {
        return true;
      }

      const max = parent?.creditNotePurchaseInvoiceSelect?.grossAmount || 0;
      const valid = (value || 0) <= max;

      if (valid) return true;

      return createError({
        message: t('maxValueX', {
          val: formatAsCurrency(parent.creditNotePurchaseInvoiceSelect.grossAmount, true),
        }),
        path: 'grossAmount',
      });
    });

  const octopusJournalKeySchema = (() => {
    const baseSchema = () =>
      yup.object({
        key: requireForFinal(yup.string),
        type: requireForFinal(yup.string),
        value: requireForFinal(yup.string),
      });

    return requireForFinal(baseSchema);
  })();

  const validationSchema = yup.object({
    amountToPay: requireForInvoice(yup.number).transform((value) =>
      Number.isNaN(value) ? null : value,
    ),
    bookYearId: requireForFinal(yup.string),
    bookYearPeriod: requireForFinal(yup.number),
    comment: yup.string().nullable(),
    creditNotePurchaseInvoiceId: yup.string().nullable(),
    description: yup.string().nullable(),
    document: requireForNew(yup.mixed),
    dueDate: requireForFinal(yup.string),
    grossAmount: grossAmountSchema,
    homeownerAssociationId: requireForFinal(yup.string),
    invoiceDate: requireForFinal(yup.string),
    invoiceId: yup.string().nullable(),
    invoiceNumber: requireForFinal(yup.string),
    lineItems: yup
      .array()
      .of(
        yup
          .object({
            accountId: requireForFinal(yup.string),
            date: requireForFinal(yup.string),
            dateUntil: yup.string().nullable(),
            description: requireForFinal(yup.string).trim(),
            divisionKey: yup.string().nullable(),
            divisionType: yup.string().nullable(),
            grossAmount: grossAmountSchema,
            isDefault: yup.boolean(),
            netAmount: yup
              .number()
              .nullable()
              .transform((value) => (Number.isNaN(value) ? 0 : value)),
            percentage: requireForFinal(yup.number).transform((value) =>
              Number.isNaN(value) ? 0 : value,
            ),
            shares: yup
              .array()
              .of(
                yup.object({
                  amount: yup
                    .number()
                    .nullable()
                    .transform((value) => (Number.isNaN(value) ? 0 : value)),
                  buildingRelationId: yup.string().nullable(),
                  percentage: yup
                    .number()
                    .nullable()
                    .transform((value) => (Number.isNaN(value) ? 0 : value)),
                  share: yup
                    .number()
                    .nullable()
                    .transform((value) => (Number.isNaN(value) ? 0 : value))
                    .required(t('fieldIsRequired')),
                  unitId: yup.string().nullable().required(t('fieldIsRequired')),
                }),
              )
              .test(
                'dividedAmountsMustMatchLineAmount',
                'dividedAmountsMustMatchLineAmount',
                (shares, schema) => {
                  const {
                    parent,
                    options: { context },
                  } = schema;
                  if (context?.isDraft) return true;

                  if (
                    !shares?.length ||
                    parent.divisionKey ||
                    parent.divisionType === DivisionType.Percentage
                  ) {
                    return true;
                  }

                  const totalSharesAmount = sumBy(shares, 'amount' ?? 0);
                  return totalSharesAmount === parent.grossAmount ?? 0;
                },
              ),
            vatAmount: requireForFinal(yup.number).transform((value) =>
              Number.isNaN(value) ? 0 : value,
            ),
            vatCode: requireForFinal(yup.string),
          })
          .test('divisionIsValid', '', (lineItem, ctx) => {
            const isDraft = ctx.options.context?.isDraft;

            if (isDraft) return true;

            const { errors, isValid } = validateIndividualDivision(lineItem as LineItem);

            if (isValid) return true;

            return ctx.createError({
              message: errors[0],
            });
          }),
      )
      .test('percentage', t('percentageMustAddToHundred'), (lineItems, { parent }) => {
        if (parent.isDraft) return true;
        if (!LineItems) return false;

        const percentageSum = sumBy(lineItems, (item) => item.percentage || 0);

        return Math.abs(percentageSum - 100) < 0.05;
      }),
    meterCode: yup.string().nullable(),
    octopusJournalKey: octopusJournalKeySchema,
    paymentMethod: requireForInvoice(yup.string),
    structuredReference: requireForFinal(yup.string).test(
      'structuredCommunicationIsValid',
      t('invalidPaymentReference'),
      (value) => {
        if (!value || !isStructuredReference(value)) return true;

        return isStructuredReferenceValid(value);
      },
    ),
    supplierAccountNumber: requireForInvoice(yup.string).test(
      'mustBeValid',
      t('ChecksumNotNumber'),
      (value) => {
        if (!value) return true;

        return ibantools.isValidIBAN(removeWhitespaces(value));
      },
    ),
    supplierBic: requireForInvoice(yup.string),
    supplierCompanyId: requireForFinal(yup.string),
  });

  return {
    enableReinitialize: true,
    initialValues,
    validationSchema,
  } as const;
};

export default useInvoiceValidationFormikContext;
