import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { forwardRef } from 'react';

export type NumericFormatAdapterProps = {
  onChange?: (event: { target: { name: string; value: string } }) => void;
  name?: string;
};

const NumericFormatAdapter = forwardRef<
  HTMLInputElement,
  NumericFormatAdapterProps & NumericFormatProps
>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      valueIsNumericString
      decimalSeparator=","
      decimalScale={2}
    />
  );
});

export default NumericFormatAdapter;
