import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useDocumentTypeOptions = () => {
  const { t } = useTranslation();

  const documentTypeSuggestions = useMemo(() => {
    const options = [
      t('paymentRequest'),
      t('estimate'),
      t('contract'),
      t('expertReport'),
      t('invoice'),
      t('image'),
      t('leakDetectionReport'),
      t('procuration_one'),
    ];
    return options.sort((a, b) => a.localeCompare(b));
  }, [t]);

  return documentTypeSuggestions;
};

export default useDocumentTypeOptions;
