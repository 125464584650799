import { cloneElement, useContext, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { IconButton } from '@mui/joy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FileContext, FileContextParentType } from '@/FileContext';
import { useGetCase, useGetCompanyTeams, useGetPolicy } from '@/queries';
import API from '@/shared/api/ApiService';
import { AppContext } from '@/shared/context/context';
import DocumentPropTypes from '@/shared/prop-types/DocumentPropTypes';
import useNotification from '@/shared/hooks/UseNotification';
import useToggle from '@/shared/hooks/UseToggle';

import { AccessibilityIcon } from '@/shared/icons/Icons';
import Autocomplete from '../2.0/Autocomplete';
import KpcModal from '../2.0/layout/KpcModal';
import KpcTooltip from '../Tooltips/KpcTooltip';
import TextCheckbox from '../checkboxes/TextCheckbox';

const propTypes = {
  customButton: PropTypes.node,
  file: DocumentPropTypes.isRequired,
};

const DocumentAccessibility = ({ customButton = null, file }) => {
  const [entityId, setEntityId] = useState();

  const { currentTeam } = useContext(AppContext);
  const { t } = useTranslation();
  const { sendDefaultError, sendNotification } = useNotification();
  const { setFiles, parentType, parentTypeId } = useContext(FileContext);

  const toggle = useToggle();

  const { companyTeams, companyTeamsLoading } = useGetCompanyTeams({ enabled: toggle.value });
  const { currentCase } = useGetCase(entityId?.caseId);
  const { policy } = useGetPolicy(entityId?.policyId);

  const getTooltipTitle = () => {
    const { teamsWithAccess, isPublic } = file;

    if (isPublic) {
      return t('publicFileInfo');
    }
    return teamsWithAccess.map((twa) => twa.name).join(', ');
  };

  const toggleOpen = async (open) => {
    if (file.readOnly) {
      return;
    }
    toggle.setValue(open);
  };

  const getCustomButton = () => {
    if (customButton == null) {
      return null;
    }
    return cloneElement(customButton, {
      onClick: () => toggleOpen(true),
    });
  };

  const handleSetPublic = async (isPublic) => {
    if (file.readOnly) {
      return;
    }

    const { teamsWithAccess, id: fileId } = file;
    const data = {
      isPublic,
      teamsWithAccess: teamsWithAccess?.map((twa) => twa.id),
    };
    const response = await API.putUpdateFileAccessibility(fileId, data);

    if (response.serviceError != null || response.status !== 200) {
      sendDefaultError(response);
      return;
    }

    sendNotification({
      header: t('success'),
      message: t('typeSuccessfullyUpdated', { type: t('file') }),
      variant: 'success',
    });

    setFiles((prev) => prev.map((f) => (f.id === fileId ? { ...f, isPublic } : f)));
  };

  const teamsWithAccessChanged = async (data) => {
    if (file.readOnly) {
      return;
    }

    const { value: teamsWithAccess } = data;
    const newTeams =
      teamsWithAccess?.map(({ value, label }) => ({
        id: value,
        name: label,
      })) ?? [];

    if (!newTeams.some((teams) => teams.id === currentTeam.id)) {
      newTeams.push({ id: currentTeam.id, name: currentTeam.name });
    }

    const { isPublic, id: fileId } = file;
    const apiData = {
      caseId: currentCase?.id,
      isPublic,
      teamsWithAccess: newTeams?.map((twa) => twa.id),
    };
    const response = await API.putUpdateFileAccessibility(fileId, apiData);

    if (response.serviceError != null || response.status !== 200) {
      sendDefaultError(response);
      return;
    }

    sendNotification({
      header: t('success'),
      message: t('typeSuccessfullyUpdated', { type: t('file') }),
      variant: 'success',
    });

    setFiles((prev) =>
      prev.map((f) => (f.id === fileId ? { ...f, teamsWithAccess: newTeams } : f)),
    );
  };

  useEffect(() => {
    switch (parentType) {
      case FileContextParentType.Case: {
        setEntityId({ caseId: parentTypeId });
        break;
      }
      case FileContextParentType.Policy: {
        setEntityId({ policyId: parentTypeId });
        break;
      }
      default:
        setEntityId();
        break;
    }
  }, [parentType, parentTypeId]);

  const options = useMemo(() => {
    let caseOptions = [];
    let policyOptions = [];
    let networkOptions = [];

    if (currentCase && currentCase.relations) {
      caseOptions = currentCase.relations
        .filter((rel) => !!rel.teamId)
        .map((rel) => ({ id: rel.id, label: rel.displayName, value: rel.teamId }));
    }

    if (policy && policy.relations) {
      policyOptions = policy.relations
        .filter((rel) => !!rel.teamId)
        .map((rel) => ({ id: rel.id, label: rel.displayName, value: rel.teamId }));
    }

    if (companyTeams) {
      networkOptions = companyTeams
        .map((comp) =>
          comp.teams.map((team) => ({
            id: team.teamId,
            label: `${comp.companyName} (${team.name})`,
            value: team.teamId,
          })),
        )
        .flat();
    }

    return caseOptions
      .concat(networkOptions, policyOptions)
      .filter(({ value }, index, self) => self.findIndex((v) => v.value === value) === index);
  }, [companyTeams, currentCase, policy]);

  return (
    <>
      <KpcTooltip title={<span style={{ whiteSpace: 'pre-line' }}>{getTooltipTitle()}</span>}>
        {customButton != null ? (
          getCustomButton()
        ) : (
          <IconButton
            variant="plain"
            color="neutral"
            onClick={() => toggleOpen(true)}
            className={classnames(file.readOnly && 'cursor-default')}
          >
            <AccessibilityIcon color={file.isPublic && 'keypoint'} />
          </IconButton>
        )}
      </KpcTooltip>
      {!file.readOnly && (
        <KpcModal title={t('accessibility')} toggle={toggle}>
          <>
            {!file.isPublic && (
              <Autocomplete
                label={t('search.label')}
                name="teams"
                multiple
                options={options}
                onChange={teamsWithAccessChanged}
                value={
                  options.filter((option) =>
                    file.teamsWithAccess.some((team) => team.id === option.value),
                  ) || []
                }
                loading={companyTeamsLoading}
              />
            )}
            <TextCheckbox
              yesText={t('publicFileInfo')}
              noText={t('privateFileInfo')}
              isChecked={file.isPublic}
              callback={handleSetPublic}
            />
          </>
        </KpcModal>
      )}
    </>
  );
};

DocumentAccessibility.propTypes = propTypes;

export default DocumentAccessibility;
