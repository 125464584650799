import { Tooltip, Typography } from '@mui/joy';
import { useContext, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { decapitalize, getTaskDescription, sentencize } from '@/shared/utils/helpers';
import { AppContext } from '@/shared/context/context';
import CasePropTypes from '@/shared/prop-types/CasePropTypes';
import PriorityIcon from '@/shared/icons/PriorityIcon';
import TaskPropTypes from '@/shared/prop-types/TaskPropTypes';
import { trpc } from '@/config/trpc';
import useToggle from '@/shared/hooks/UseToggle';

import { EditIcon, TeamIcon, UserIcon, WarningIcon } from '@/shared/icons/Icons';
import AddEditTaskModal from '../../../../Tasks/EditTask/AddEditTaskModal';
import FinishTaskButton from '../../../../Tasks/Buttons/FinishTaskButton';
import IconButton from '@/shared/components/buttons/IconButton';
import KpcAvatarGroup from '@/shared/components/avatars/KpcAvatarGroup';
import SnoozeTaskButton from '../../../../Tasks/Buttons/SnoozeTaskButton';
import useDayjs from '@/shared/hooks/useDayjs';

const TaskItem = ({ task, canEdit, currentCase }) => {
  const { dayjs, formatDate } = useDayjs();

  const { data: teamsAndInvitations } = trpc.team.teamsAndInvitations.useQuery();
  const { currentTeamUser } = useContext(AppContext);
  const { t } = useTranslation();
  const editToggle = useToggle();

  const team = useMemo(
    () =>
      teamsAndInvitations?.find((e) => e.id === task.taskUsers.find((u) => u.isAssigned).teamId)
        ?.name,
    [teamsAndInvitations, task],
  );

  const isDue = task.dueDate && dayjs(task.dueDate).isBefore(dayjs(), 'day');

  return (
    <div
      className="mb-4 flex flex-col space-y-2 rounded-xl border border-gray-200 p-2"
      key={task.id}
    >
      <div className="flex space-x-2">
        <div className="flex grow space-x-2">
          <Tooltip title={`${t('priority')}: ${t(decapitalize(task.priority))}`}>
            <i className={`priority-${decapitalize(task.priority)}`}>
              <PriorityIcon priority={task.priority} />
            </i>
          </Tooltip>

          <Typography
            className="items-start text-xs"
            startDecorator={
              task.taskUsers.some(
                (tu) => tu.isAssigned && tu.teamUserId === currentTeamUser?.id,
              ) ? (
                <Tooltip title={task.taskUsers.find((tu) => tu.isAssigned).displayName}>
                  <UserIcon />
                </Tooltip>
              ) : (
                <Tooltip title={team}>
                  <TeamIcon />
                </Tooltip>
              )
            }
          >
            {getTaskDescription(t, task)}
          </Typography>
        </div>

        <div>
          <Typography
            className={classNames('text-xs', { 'text-red-500': isDue })}
            startDecorator={
              isDue && (
                <Tooltip title={t('dueDateHasBeenPassed')}>
                  <WarningIcon className="text-red-500" />
                </Tooltip>
              )
            }
          >
            {formatDate(task.dueDate)}
          </Typography>
        </div>
      </div>

      <div className="flex justify-between">
        <KpcAvatarGroup
          names={task.taskUsers.filter((tu) => tu.isAssigned).map((a) => a.displayName)}
          small
        />
        {canEdit && (
          <div className="flex space-x-2 text-primary">
            {task && (
              <>
                <IconButton
                  iconComponent={<EditIcon />}
                  disabled={task.completedDate != null}
                  color="keypoint"
                  onClick={() => editToggle.show()}
                  tooltip={
                    task.completedDate != null
                      ? t('cannotChangeFinishedTask')
                      : sentencize(t('editType', { type: t('task') }))
                  }
                />
                <AddEditTaskModal
                  toggle={editToggle}
                  initialTask={task}
                  currentCase={currentCase}
                />
              </>
            )}
            <SnoozeTaskButton task={task} />
            <FinishTaskButton task={task} currentCase={currentCase} />
          </div>
        )}
      </div>
    </div>
  );
};

TaskItem.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  currentCase: CasePropTypes,
  task: TaskPropTypes.isRequired,
};

export default TaskItem;
