import { Attributes, createElement, useMemo } from 'react';
import { i18n } from 'i18next';
import { Tooltip } from '@mui/joy';

import { Nullable } from '~/common/types';
import useTruncated from '@/shared/hooks/useTruncated';

type Props = {
  type?: Nullable<string>;
  column: {
    key: string;
    title?: string;
    customHeaderComponent?: React.FC;
  };
  t: i18n['t'];
  // All other props
  [x: string]: any;
} & Attributes;

const DefaultHeadCell: React.FC<Props> = (props) => {
  const {
    type = null,
    column: { customHeaderComponent, key, title },
    t,
  } = props;

  const { childRef, containerRef, isTruncated } = useTruncated();

  const tableHeadTitle = useMemo(() => {
    if (title === '') {
      return '';
    }

    return title ? t(title) : (type && t(`_${type}.columns.${key}`)) || '';
  }, [type, key, title, t]);
  if (customHeaderComponent) {
    return createElement(customHeaderComponent, props);
  }

  return (
    <div ref={containerRef}>
      <div className="inline-block" ref={childRef}>
        {!isTruncated ? (
          <div className="inline-block whitespace-nowrap">{tableHeadTitle}</div>
        ) : (
          <Tooltip className="whitespace-nowrap" placement="top" title={tableHeadTitle}>
            <div className="inline-block">{tableHeadTitle}</div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default {
  DefaultHeadCell,
};
