import * as yup from 'yup';
import {
  Autocomplete,
  AutocompleteOption,
  Button,
  FormControl,
  ListItemContent,
  Tooltip,
} from '@mui/joy';
import { Form, Formik } from 'formik';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useRejectCase, useUpdateCaseCaseStatus } from '../../../mutations';
import CaseStatus from '../../enums/CaseStatus';
import CaseStatusType from '../../enums/CaseStatusType';
import Language from '../../enums/Language';
import LanguageCode from '../../enums/LanguageCode';
import { useGetCaseStatuses } from '../../../queries';
import useToggle from '../../hooks/UseToggle';

import { CancelIcon, InlineSpinner, SaveIcon } from '../../icons/Icons';
import { CaseContext } from '@/containers/Cases/CaseContext';
import { decapitalize } from '../../utils/helpers';
import FormikTextField from '../2.0/formik/FormikTextField';
import FormikYesNo from '../../formik/FormikYesNo';
import KpcModal from '../2.0/layout/KpcModal';
import { trpc } from '@/config/trpc';

const STATUS_COLOR = {
  [CaseStatus.Open]: 'success',
  [CaseStatus.Closed]: 'danger',
  [CaseStatus.Cancelled]: 'danger',
  [CaseStatus.Pending]: 'warning',
};

const propTypes = {
  callback: PropTypes.func,
  disabled: PropTypes.bool,
  selectFromEntity: PropTypes.bool,
};

const SelectCaseStatus = ({ callback, disabled = false, selectFromEntity = false }) => {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState();

  const previousSelectedValue = useRef();
  const textFieldRef = useRef();

  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { currentCase, ownRelation, caseType } = useContext(CaseContext);

  const { caseStatuses, caseStatusesIsLoading } = useGetCaseStatuses();
  const { data: caseTasks } = trpc.task.caseTasks.useQuery({ caseId: currentCase.id });

  const finishAllTasksToggle = useToggle();
  const declineCaseToggle = useToggle();

  const formatCaseStatus = useCallback(
    (status) => {
      const { id, value, translations, statusType, ...rest } = status;

      let description;

      if (translations == null) {
        description = t(decapitalize(value));
      } else {
        switch (language) {
          case LanguageCode.en:
            description = translations.find((tr) => tr.language === Language.English)?.description;
            break;
          case LanguageCode.fr:
            description = translations.find((tr) => tr.language === Language.French)?.description;
            break;
          case LanguageCode.nl:
            description = translations.find((tr) => tr.language === Language.Dutch)?.description;
            break;
          default:
            description = translations.find((tr) => tr.language === Language.English)?.description;
            break;
        }
      }

      return {
        description,
        id: id ?? value,
        statusType,
        statusTypeTranslated: t(decapitalize(statusType ?? '-')),
        value,
        ...rest,
      };
    },
    [language, t],
  );

  const { rejectCase, isRejectingCase } = useRejectCase();
  const { updateCaseStatus, isUpdatingCaseStatus } = useUpdateCaseCaseStatus();

  useEffect(() => {
    if (options && options.length > 0) {
      const currentOption = options.find(
        (o) => o.id === currentCase.status.id || o.id === currentCase.status.value,
      );

      if (!currentOption) {
        const formatted = formatCaseStatus(currentCase.status);

        setOptions([...options, formatted]);
        setSelectedValue(formatted);
        return;
      }

      setSelectedValue(currentOption);
    }
  }, [formatCaseStatus, options, currentCase]);

  useEffect(() => {
    if (caseStatusesIsLoading) {
      return;
    }
    let possibleOptions = caseStatuses.filter((s) => s.active || s.isSystemDefault);

    if (currentCase.status.statusType === CaseStatusType.Pending) {
      possibleOptions = possibleOptions.filter(
        (o) => o.statusType === currentCase.status.statusType,
      );
    } else if (selectFromEntity) {
      possibleOptions = possibleOptions.filter((o) => o.statusType === CaseStatusType.Open);
    } else {
      possibleOptions = possibleOptions.filter((o) => o.statusType !== CaseStatusType.Pending);
    }

    if (caseType == null) {
      setOptions(possibleOptions.map((s) => formatCaseStatus(s)));
      return;
    }

    setOptions(
      possibleOptions
        .filter((cs) => cs.caseType === caseType || cs.caseType == null)
        .map((s) => formatCaseStatus(s)),
    );
  }, [
    caseStatusesIsLoading,
    caseStatuses,
    caseType,
    formatCaseStatus,
    language,
    t,
    currentCase.status,
    selectFromEntity,
  ]);

  const changeCaseStatus = async (data) => {
    if (isRejectingCase || isUpdatingCaseStatus) {
      return;
    }

    if (selectFromEntity) {
      callback(data);
      return;
    }

    const { id, finishAllCaseTasks, statusReason, statusType, value } = data;

    if (statusType === CaseStatusType.Cancelled) {
      rejectCase(
        {
          caseId: currentCase.id,
          caseStatusId: value == null ? id : null,
          finishAllCaseTasks,
          status: statusType,
          statusReason,
        },
        {
          onError: () => setSelectedValue(formatCaseStatus(currentCase.status)),
          onSettled: () => declineCaseToggle.hide(),
        },
      );
      return;
    }

    updateCaseStatus(
      {
        caseId: currentCase.id,
        caseStatusId: value == null ? id : null,
        finishAllCaseTasks,
        status: value,
      },
      {
        onSettled: () => finishAllTasksToggle.hide(),
      },
    );
  };

  const onChange = (e, val) => {
    previousSelectedValue.current = selectedValue;

    setSelectedValue(val);

    if (val.statusType === CaseStatusType.Cancelled && !selectFromEntity) {
      declineCaseToggle.show();
      setTimeout(() => textFieldRef.current.focus(), 0);
      return;
    }

    if (
      val.statusType === CaseStatusType.Closed &&
      caseTasks.some((ct) => !ct.completedDate) &&
      !selectFromEntity
    ) {
      finishAllTasksToggle.show();
      return;
    }

    changeCaseStatus(val);
  };

  const canEdit =
    ownRelation.isOwner ||
    (currentCase.status.statusType === CaseStatusType.Pending && ownRelation.isRequestor);
  const statusOrder = [CaseStatusType.Closed, CaseStatusType.Open, CaseStatusType.Cancelled];

  return (
    <>
      <Tooltip title={selectedValue?.description ?? '-'}>
        <Autocomplete
          size="sm"
          color={
            selectFromEntity ? 'neutral' : STATUS_COLOR[selectedValue?.statusType] ?? 'neutral'
          }
          slotProps={{
            listbox: {
              color: 'neutral',
            },
          }}
          noOptionsText={t('noOptions')}
          disabled={disabled || !canEdit}
          value={selectedValue ?? '-'}
          onChange={onChange}
          options={options.sort((a, b) => {
            const aStatusIndex = statusOrder.indexOf(a.statusType);
            const bStatusIndex = statusOrder.indexOf(b.statusType);

            return aStatusIndex - bStatusIndex || a.description.localeCompare(b.description);
          })}
          groupBy={(opt) => opt.statusTypeTranslated}
          getOptionDisabled={(opt) => !opt.active && !opt.isSystemDefault}
          getOptionLabel={(opt) => opt?.description ?? opt}
          variant={selectFromEntity ? 'outlined' : 'soft'}
          disableClearable
          renderOption={(props, option, { selected }) => (
            <AutocompleteOption
              {...props}
              className={`bg-white ${selected ? 'font-semibold' : ''}`}
              color="neutral"
              key={option.id}
            >
              <ListItemContent>{option.description}</ListItemContent>
            </AutocompleteOption>
          )}
        />
      </Tooltip>
      <KpcModal
        disablePortal
        title={t('doYouWishToAlsoFinishAllTheOngoingTasks')}
        toggle={{
          ...finishAllTasksToggle,
          hide: () => {
            setSelectedValue(previousSelectedValue.current);
          },
        }}
      >
        <div className="flex justify-end space-x-4 pt-4">
          <Button
            variant="soft"
            color="neutral"
            onClick={() => {
              changeCaseStatus(selectedValue);
            }}
            disabled={isUpdatingCaseStatus}
          >
            {t('no')}
          </Button>
          <Button
            variant="solid"
            onClick={() => {
              changeCaseStatus({
                ...selectedValue,
                finishAllCaseTasks: true,
              });
            }}
            disabled={isUpdatingCaseStatus}
          >
            {t('yes')}
          </Button>
        </div>
      </KpcModal>

      <KpcModal
        title={t('areYouSure')}
        toggle={{
          ...declineCaseToggle,
          hide: () => {
            setSelectedValue(formatCaseStatus(currentCase.status));
            declineCaseToggle.hide();
          },
        }}
      >
        <Formik
          initialValues={{
            finishAllCaseTasks: true,
            statusReason: '',
          }}
          validationSchema={yup.object().shape({
            finishAllCaseTasks: yup.bool(),
            statusReason: yup.string().nullable().required(t('errors:fieldIsRequired')),
          })}
          onSubmit={(values) => {
            changeCaseStatus({
              ...selectedValue,
              ...values,
            });
          }}
        >
          <Form>
            <div className="pl-2">
              <FormControl>
                <FormikTextField
                  id="statusReason"
                  name="statusReason"
                  label={t('caseCancelledReason')}
                  required
                  ref={textFieldRef}
                />
              </FormControl>

              <FormControl>
                <FormikYesNo
                  id="finishAllCaseTasks"
                  name="finishAllCaseTasks"
                  yesText={t('closeAllOpenTasks')}
                  noText={t('keepTasksOpen')}
                  customClass="mt-3"
                />
              </FormControl>
            </div>
            <div className="flex justify-end space-x-4 pt-4">
              <Button
                startDecorator={<CancelIcon />}
                size="sm"
                variant="soft"
                color="neutral"
                onClick={() => {
                  setSelectedValue(formatCaseStatus(currentCase.status));
                  declineCaseToggle.hide();
                }}
                disabled={isRejectingCase}
              >
                {t('buttons.cancel')}
              </Button>
              <Button
                size="sm"
                variant="solid"
                type="submit"
                disabled={isRejectingCase}
                startDecorator={isRejectingCase ? <InlineSpinner /> : <SaveIcon />}
              >
                {t('buttons.save')}
              </Button>
            </div>
          </Form>
        </Formik>
      </KpcModal>
    </>
  );
};

SelectCaseStatus.propTypes = propTypes;

export default SelectCaseStatus;
