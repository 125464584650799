import { AutocompleteOption, PaginatedAutocomplete } from '~/common/types';
import {
  Case,
  CaseAmount,
  CaseInGroup,
  DetailedCaseRelation,
  RepairMandate,
  UpdateCaseStatus,
  UpdateEntityRemarkData,
} from '@/types/cases';
import {
  RequestRepairInKindRequest,
  UpdateAxaServiceReferenceRequest,
  UpdateEntityTeamInfoRequest,
} from '~/common/types/case/caseRequests';
import { DetailedCaseTemplate } from '~/common/types/case/caseTemplate';
import { File } from '@/types/documents';
import { TeamWithAccessToCase } from '~/common/types/case/teamWithAccessToCase';

import config from '~/frontend/config/config';
import createRestClient from '../restClient';
import { objectToFormData } from '@/shared/utils/helpers';
import { SendEmailFromCaseRequest } from '~/common/types/email/emailMessage';

const client = createRestClient(config.baseURL);

const getCasesAutocomplete = (params: Record<string, unknown>): Promise<PaginatedAutocomplete> =>
  client.get('/case/autocomplete', { params });

const getCaseClassificationsAutocomplete = (
  params: Record<string, unknown>,
): Promise<PaginatedAutocomplete> => client.get('/case/classification/autocomplete', { params });

const getCaseStatusesAutocomplete = (
  params: Record<string, unknown>,
): Promise<AutocompleteOption[]> => client.get('/case/status/autocomplete', { params });

const getCase = (
  id: string,
  params: Record<string, unknown>,
): Promise<
  Case & {
    relatedData: Record<string, unknown>;
  }
> => client.get(`/case/${id}`, { params });

const getCaseTemplate = (
  id: string,
  params: Record<string, unknown>,
): Promise<DetailedCaseTemplate> => client.get(`/case/template/${id}`, { params });

const updateEntityTeamInfo = (data: UpdateEntityTeamInfoRequest) =>
  client.put('/case/entity/teamInfo', data);

const restClient = createRestClient();

// @ts-ignore
const addNote = (data) => restClient.post('case/note', data);

// @ts-ignore
const getCases = ({ page, params }) => restClient.get(`case/page/${page}?${params}`);

// @ts-ignore
const searchCases = (searchTerm) => restClient.get(`case/search/${searchTerm}`);

const getSelectorCases = () => restClient.get('case/selector.list');

// @ts-ignore
const postAddAppointment = (data) => restClient.post('case/appointment', data);

// @ts-ignore
const postRegisterPhoneCall = (data) => restClient.post('case/phone-call', data);

// @ts-ignore
const putAppointment = (data) => restClient.put('case/appointment', data);

// @ts-ignore
const putPhoneCall = (data) => restClient.put('case/phone-call', data);

const getCasesCount: (status: string) => Promise<CaseAmount> = (status) =>
  restClient.get(`case/amount?status=${status}`);

const getCaseFiles = (caseId: string): Promise<File[]> =>
  restClient.get(`case/${caseId}/file.list`);

const getCaseEntities = (caseId: string) => restClient.get(`case/${caseId}/entities`);

const getCaseEntityAccess = (caseId: string, entityId: string) =>
  restClient.get(`case/${caseId}/entity/${entityId}/access`);

// @ts-ignore
const copyRepairCase = (data) => restClient.post(`repair/copy`, data);

const getCaseInGroup: (caseGroupId: string) => Promise<CaseInGroup[]> = (caseGroupId) =>
  restClient.get(`case/group/${caseGroupId}`);

const getCaseTemplates = (offset: string): Promise<Record<string, unknown>> =>
  restClient.get(`caseTemplate?offset=${offset}`);

// @ts-ignore
const putCaseEntityInfo = (data) => restClient.put('case/entity', data);

const putCaseEntityRemark = (data: UpdateEntityRemarkData) =>
  restClient.put('case/entity/remark', data);

const requestRepairInKind: (data: RequestRepairInKindRequest) => Promise<string> = (data) =>
  client.post('/case/request-repair-in-kind', objectToFormData(data), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

// @ts-ignore
const putInsurancePolicy = (data) => restClient.put('insuranceClaim/policy', data);

const getCaseRelation: (props: {
  caseId: string;
  relationId: string;
}) => Promise<DetailedCaseRelation> = ({ caseId, relationId }) =>
  restClient.get(`case/${caseId}/relation/${relationId}`);

const deleteCaseEntity = async (caseId: string, entityId: string) =>
  restClient.delete(`case/${caseId}/entity/${entityId}`);

const postSendEmailMessage = (data: SendEmailFromCaseRequest) =>
  restClient.post(
    'case/send-email',
    // we must use custom function so attachments have key `attachments` and not `attachments[]` as
    // is produced by axios automatic generating of form data
    objectToFormData(data),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

// @ts-ignore
const postParseEmail = (file) =>
  restClient.post(
    'case/parse-mail',
    { file },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

// @ts-ignore
const postRegisterEmail = (data) =>
  restClient.post('case/register-email', objectToFormData(data), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
// @ts-ignore

const copyEmailsToCase = (data) => restClient.post('case/copy-emails-to-case', data);

const getPreviousCreatedCase = (caseId: string) => restClient.get(`case/previous-case/${caseId}`);

const getNextCreatedCase = (caseId: string) => restClient.get(`case/next-case/${caseId}`);

// @ts-ignore
const putCaseStatus = (data: UpdateCaseStatus) => restClient.put('case/status', data);

// @ts-ignore
const putRejectCase = (data) => restClient.put('case/reject', data);

/* Repair case */
// @ts-ignore
const putRepairPolicy = (data) => restClient.put('repair/policy', data);

// @ts-ignore
const putAssignContractor = (data) => restClient.put('repair/contractor', data);

const getRepairMandate: (caseId: string) => Promise<RepairMandate> = (caseId) =>
  restClient.get(`repair/${caseId}/mandate`);

// @ts-ignore
const updateRepairMandate = (data) => restClient.put('repair/mandate', data);

const getRequireEstimatePhotos = (caseId: string) =>
  restClient.get(`repair/require-estimate-photos?caseId=${caseId}`);

const getRequireInvoicePhotos = (caseId: string) =>
  restClient.get(`repair/require-invoice-photos?caseId=${caseId}`);

const updateAxaServiceReference = (data: UpdateAxaServiceReferenceRequest) =>
  client.put('/case/axa-reference', data);

// @ts-ignore
const updateNote = (data) => restClient.put('case/note', data);

const deleteCaseFiles = ({ caseId, caseFileIds }: { caseId: string; caseFileIds: string[] }) =>
  restClient.delete(`case/${caseId}/files`, { data: caseFileIds });

const getTeamsWithAccessToMyCases: () => Promise<TeamWithAccessToCase[]> = () =>
  restClient.get('case/teams');

const updateCaseRelationClassification = (data: any) =>
  restClient.put('case/relation/classification', data);

const createCase = (data: any) => restClient.post('case', data);

const createComplaint = (data: any) => restClient.post('complaint', data);

const createRepairCase = (data: any) => restClient.post('repair/create', data);

const updateCaseBuildingRelation = (data: any) => restClient.put('case/relation/building', data);

const createInsuranceClaim = (data: any) => restClient.post('insuranceClaim', data);

export default {
  addNote,
  copyEmailsToCase,
  copyRepairCase,
  createCase,
  createComplaint,
  createInsuranceClaim,
  createRepairCase,
  deleteCaseEntity,
  deleteCaseFiles,
  getCase,
  getCaseClassificationsAutocomplete,
  getCaseEntities,
  getCaseEntityAccess,
  getCaseFiles,
  getCaseInGroup,
  getCaseRelation,
  getCases,
  getCasesAutocomplete,
  getCasesCount,
  getCaseStatusesAutocomplete,
  getCaseTemplate,
  getCaseTemplates,
  getNextCreatedCase,
  getPreviousCreatedCase,
  getRepairMandate,
  getRequireEstimatePhotos,
  getRequireInvoicePhotos,
  getSelectorCases,
  getTeamsWithAccessToMyCases,
  postAddAppointment,
  postParseEmail,
  postRegisterEmail,
  postRegisterPhoneCall,
  postSendEmailMessage,
  putAppointment,
  putAssignContractor,
  putCaseEntityInfo,
  putCaseEntityRemark,
  putCaseStatus,
  putInsurancePolicy,
  putPhoneCall,
  putRejectCase,
  putRepairPolicy,
  requestRepairInKind,
  searchCases,
  updateAxaServiceReference,
  updateCaseBuildingRelation,
  updateCaseRelationClassification,
  updateEntityTeamInfo,
  updateNote,
  updateRepairMandate,
};
