import { getIn, useFormikContext } from 'formik';

import IbanInput from '../components/inputs/IbanInput';

type Props = {
  name?: string;
  required?: boolean;
  [key: string]: unknown;
};

const FormikIbanInput: React.FC<Props> = ({ name = 'iban', ...rest }) => {
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();

  const isTouched = getIn(touched, name);
  const value = getIn(values, name);
  const error = getIn(touched, name) ? getIn(errors, name) : undefined;

  return (
    <IbanInput
      callback={({ iban }) => {
        setFieldTouched(name, true);
        setFieldValue(name, iban);
      }}
      error={error}
      value={value}
      touched={isTouched}
      name={name}
      onBlur={() => setFieldTouched(name, true)}
      {...rest}
    />
  );
};

export default FormikIbanInput;
