enum BicValue {
  AARBBEB1 = 'AARBBEB1',
  ABERBE22 = 'ABERBE22',
  ABNABE2A = 'ABNABE2A',
  ARSPBE22 = 'ARSPBE22',
  FTNOBEB1 = 'FTNOBEB1',
  WAFABEBB = 'WAFABEBB',
  AXABBE22 = 'AXABBE22',
  BMPBBEBB = 'BMPBBEBB',
  BBVABEBB = 'BBVABEBB',
  BSCHBEBR = 'BSCHBEBR',
  BCMCBEBB = 'BCMCBEBB',
  DEGRBEBB = 'DEGRBEBB',
  JVBABE22 = 'JVBABE22',
  BNAGBEBB = 'BNAGBEBB',
  BOFABE3X = 'BOFABE3X',
  BARBBEBB = 'BARBBEBB',
  BKCHBEBB = 'BKCHBEBB',
  BKIDBE22 = 'BKIDBE22',
  CLIQBEB1 = 'CLIQBEB1',
  BCDMBEBB = 'BCDMBEBB',
  CPHBBE75 = 'CPHBBE75',
  BLUXBE41 = 'BLUXBE41',
  ENIBBEBB = 'ENIBBEBB',
  BILLBEBB = 'BILLBEBB',
  CMCIBEB1 = 'CMCIBEB1',
  GKCCBEBB = 'GKCCBEBB',
  CTBKBEBX = 'CTBKBEBX',
  BIBLBE21 = 'BIBLBE21',
  BMEUBEB1 = 'BMEUBEB1',
  BNPABEBB = 'BNPABEBB',
  GEBABEBB = 'GEBABEBB',
  PARBBEBZ = 'PARBBEBZ',
  PCHQBEBB = 'PCHQBEBB',
  BPOTBEB1 = 'BPOTBEB1',
  BYBBBEBB = 'BYBBBEBB',
  CVMCBEBB = 'CVMCBEBB',
  ISAEBEBB = 'ISAEBEBB',
  CREGBEBB = 'CREGBEBB',
  CEKVBE81 = 'CEKVBE81',
  CITIBEBX = 'CITIBEBX',
  RABOBE23 = 'RABOBE23',
  COBABEBX = 'COBABEBX',
  CBPXBE99 = 'CBPXBE99',
  CPBIFRPP = 'CPBIFRPP',
  CRLYBEBB = 'CRLYBEBB',
  CFFRBEB1 = 'CFFRBEB1',
  FBHLBE22 = 'FBHLBE22',
  NICABEBB = 'NICABEBB',
  DELEBE22 = 'DELEBE22',
  DHBNBEBB = 'DHBNBEBB',
  DEUTBEBE = 'DEUTBEBE',
  DIERBE21 = 'DIERBE21',
  PRIBBEBB = 'PRIBBEBB',
  EPBFBEBB = 'EPBFBEBB',
  EURBBE99 = 'EURBBE99',
  FVLBBE22 = 'FVLBBE22',
  FORDBE21 = 'FORDBE21',
  FXBBBEBB = 'FXBBBEBB',
  GOCFBEB1 = 'GOCFBEB1',
  HABBBEBB = 'HABBBEBB',
  HSBCBEBB = 'HSBCBEBB',
  ICICBE2X = 'ICICBE2X',
  ICBKBEBB = 'ICBKBEBB',
  BBRUBEBB = 'BBRUBEBB',
  TUNZBEB1 = 'TUNZBEB1',
  JPMGBEBB = 'JPMGBEBB',
  CHASBEBX = 'CHASBEBX',
  KREDBEBB = 'KREDBEBB',
  ADIABE22 = 'ADIABE22',
  LOYDBEBB = 'LOYDBEBB',
  LOCYBEBB = 'LOCYBEBB',
  MBWMBEBB = 'MBWMBEBB',
  MHCBBEBB = 'MHCBBEBB',
  MTPSBEBB = 'MTPSBEBB',
  BOTKBEBX = 'BOTKBEBX',
  NBBEBEBB = 'NBBEBEBB',
  NATXBEB1 = 'NATXBEB1',
  DNIBBE21 = 'DNIBBE21',
  OONXBEB1 = 'OONXBEB1',
  ETHIBEBB = 'ETHIBEBB',
  PSABBEB1 = 'PSABBEB1',
  PUILBEBB = 'PUILBEBB',
  RABOBE22 = 'RABOBE22',
  FETALULL = 'FETALULL',
  HBKABE22 = 'HBKABE22',
  RCBPBEBB = 'RCBPBEBB',
  BSCHBEBB = 'BSCHBEBB',
  SHIZBEBB = 'SHIZBEBB',
  SGABBEB2 = 'SGABBEB2',
  SGPBBE99 = 'SGPBBE99',
  SBINBE2X = 'SBINBE2X',
  SMBCBEBB = 'SMBCBEBB',
  BNYMBEBB = 'BNYMBEBB',
  IRVTBEBB = 'IRVTBEBB',
  ABNABEBR = 'ABNABEBR',
  TRIOBEBB = 'TRIOBEBB',
  UBSWBEBB = 'UBSWBEBB',
  UBINBE22 = 'UBINBE22',
  UTWBBEBB = 'UTWBBEBB',
  VAPEBE22 = 'VAPEBE22',
  VDSPBE91 = 'VDSPBE91',
}

export default BicValue;
