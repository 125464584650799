import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import caseApi from '@/shared/api/cases/case';
import useNotification from '@/shared/hooks/UseNotification';

const useDeleteCaseFiles = () => {
  const { t } = useTranslation();
  const { sendNotification, sendDefaultError } = useNotification();

  const { mutate, isLoading } = useMutation({
    mutationFn: caseApi.deleteCaseFiles,
    onError: (err) => {
      sendDefaultError(err);
    },
    onSuccess: () => {
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyRemoved', {
          type: t('files').toLowerCase(),
        }),
        variant: 'success',
      });
    },
  });

  return {
    deleteCaseFiles: mutate,
    isDeletingCaseFiles: isLoading,
  };
};

export default useDeleteCaseFiles;
