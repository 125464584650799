import { Divider, Stack } from '@mui/joy';
import { useContext, useEffect } from 'react';
import { lowerFirst } from 'lodash';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import * as ibantools from 'ibantools';
import EmailAddressInput from '@/containers/Email/EmailAddressInput';
import FormikAddressForm from '@/shared/formik/FormikAddressForm';
import FormikAutocomplete from '@/shared/components/2.0/formik/FormikAutocomplete';
import FormikIbanInput from '@/shared/formik/FormikIbanInput';
import FormikInternationalPhoneNumberInput from '@/shared/components/2.0/formik/FormikInternationalPhoneNumberInput';
import FormikTextField from '@/shared/components/2.0/formik/FormikTextField';
import ModalFooter from '@/shared/components/2.0/forms/ModalFooter';
import { PhoneNumberType } from '@/shared/components/phoneNumber/InternationalPhoneNumber';

import { BicValue, CaseRole, Gender, Language } from '~/common/enums';
import { AppContext } from '@/shared/context/context';
import ContactFormikValues from '~/common/types/contact/ContactFormikValues';
import { removeWhitespaces } from '@/shared/utils/helpers';
import { TAutocompleteOption } from '~/common/types';
import { trpc } from '@/config/trpc';

type Props = {
  disabled?: boolean;
  isSubmitting: boolean;
  minified?: boolean;
  onCancel: () => void;
};

const bicOptions = Object.values(BicValue).map((bic) => ({
  label: bic,
  value: bic,
})) as TAutocompleteOption<BicValue>[];

const AddEditContactFormBody: React.FC<Props> = ({
  disabled = false,
  isSubmitting,
  minified = false,
  onCancel,
}) => {
  const { t } = useTranslation(['common', 'errors']);

  const genderOptions = Object.values(Gender).map((gender) => ({
    label: t(lowerFirst(gender)),
    value: gender,
  })) as TAutocompleteOption<Gender>[];

  const languageOptions = Object.values(Language).map((language) => ({
    label: t(lowerFirst(language)),
    value: language,
  })) as TAutocompleteOption<Language>[];

  const caseRoleOptions = Object.values(CaseRole).map((caseRole) => ({
    label: t(lowerFirst(caseRole)),
    value: caseRole,
  })) as TAutocompleteOption<CaseRole>[];

  const { isValid, values, setFieldValue } = useFormikContext<ContactFormikValues>();

  const { currentTeam } = useContext(AppContext);
  const allowCaseManagement = !!currentTeam?.allowCaseManagement;

  const { data: tags } = trpc.contact.tags.useQuery();

  const { data: resolvedBic } = trpc.ibanbic.bbanToBic.useQuery(
    values.iban ? removeWhitespaces(values.iban) : '',
    {
      enabled: !!values.iban && ibantools.isValidIBAN(removeWhitespaces(values.iban)),
    },
  );

  useEffect(() => {
    if (resolvedBic) {
      setFieldValue('bic', resolvedBic);
    }
  }, [resolvedBic, values.bic, setFieldValue]);

  return (
    <>
      <Stack rowGap={0.5}>
        <div className={allowCaseManagement ? 'flex gap-4' : ''}>
          <div className={allowCaseManagement ? 'w-1/2' : ''}>
            <FormikAutocomplete
              name="contactTags"
              id="contactTags"
              label={t('tags')}
              multiple
              options={tags || []}
            />
          </div>
          {allowCaseManagement && (
            <div className="w-1/2">
              <FormikAutocomplete
                name="defaultCaseRole"
                id="defaultCaseRole"
                label={t('defaultRole')}
                options={caseRoleOptions || []}
              />
            </div>
          )}
        </div>
        <Stack direction="row" spacing={1}>
          <FormikTextField
            id="firstName"
            name="firstName"
            label={t('common:firstName')}
            fullWidth
            type="text"
            disabled={disabled}
          />
          <FormikTextField
            label={t('common:lastName')}
            fullWidth
            id="lastName"
            name="lastName"
            type="text"
            required
            disabled={disabled}
          />
        </Stack>
        {!minified && (
          <>
            <Stack direction="row" spacing={1}>
              <FormikAutocomplete
                name="gender"
                label={t('common:gender')}
                options={genderOptions}
              />
              <FormikAutocomplete
                name="language"
                id="language"
                label={t('common:language')}
                options={languageOptions}
              />
            </Stack>
            <Divider className="my-2" />
            <EmailAddressInput />
            <Divider className="mb-2 mt-5" />
            <Stack direction="row" spacing={1}>
              <FormikInternationalPhoneNumberInput
                type={PhoneNumberType.Landline}
                className="w-full"
                name="phoneNumber"
                label={t('phoneNumber')}
                disabled={disabled}
              />
              <FormikInternationalPhoneNumberInput
                type={PhoneNumberType.Mobile}
                className="w-full"
                name="mobilePhoneNumber"
                label={t('mobilePhoneNumber')}
                disabled={disabled}
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <FormikIbanInput label={t('iban')} name="iban" id="iban" disabled={disabled} />
              <FormikAutocomplete name="bic" label={t('bic')} options={bicOptions} />
            </Stack>
            <FormikAddressForm disabled={disabled} />
          </>
        )}
      </Stack>

      <ModalFooter disableSubmit={!isValid} isSubmitting={isSubmitting} onCancel={onCancel} />
    </>
  );
};

export default AddEditContactFormBody;
