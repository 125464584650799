import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Autocomplete from '@/shared/components/2.0/Autocomplete';
import { localStorageKeys } from '@/shared/utils/constants';
import { trpc } from '@/config/trpc';
import { useMailboxContext } from '../store';

const propTypes = {
  allowUserAccounts: PropTypes.bool,
  alwaysShow: PropTypes.bool,
  className: PropTypes.string,
  clearMailboxState: PropTypes.bool,
  disabled: PropTypes.bool,
  showLabel: PropTypes.bool,
};

const EmailAccountsAutocomplete = ({
  className = '',
  clearMailboxState = true,
  showLabel = false,
  disabled = false,
  allowUserAccounts = true,
  alwaysShow = false,
}) => {
  const { t } = useTranslation();

  const { data: emailAccounts, isLoading } = trpc.mailbox.accounts.get.useQuery(
    allowUserAccounts ? undefined : true,
  );
  const { data: emailAccountsAutocomplete, isLoading: autoCompleteEmailAccountsAreLoading } =
    trpc.mailbox.accounts.autoComplete.useQuery(allowUserAccounts ? undefined : true);

  const {
    setEmailAccount,
    state: { emailAccount },
  } = useMailboxContext();

  const { linkId: emailAccountLinkId } = emailAccount ?? {};

  useEffect(() => {
    if (!emailAccounts || emailAccountLinkId) {
      return;
    }

    const matchingEmailAccount = emailAccounts.find(
      (account) =>
        account.linkId === localStorage.getItem(localStorageKeys.mailbox.SELECTED_EMAIL_LINK_ID),
    );

    if (matchingEmailAccount) {
      setEmailAccount(matchingEmailAccount, clearMailboxState);
    } else if (emailAccounts.length === 1) {
      setEmailAccount(emailAccounts[0], clearMailboxState);
    } else if (emailAccounts.length > 1) {
      setEmailAccount(
        emailAccounts.find((account) => !account.isTeamMailbox) ?? emailAccounts[0],
        clearMailboxState,
      );
    }
  }, [clearMailboxState, emailAccounts, emailAccountLinkId, setEmailAccount]);

  const updateEmailAccount = ({ value: selectedOption }) => {
    const selected =
      emailAccounts.find((account) => account.linkId === selectedOption.value) ?? null;
    setEmailAccount(selected, clearMailboxState);
  };

  const selectedOption = emailAccounts?.find((account) => account.linkId === emailAccountLinkId);

  return emailAccounts && (alwaysShow || emailAccounts.length > 1) ? (
    <div className={classNames(className)}>
      <Autocomplete
        name="emailAccount"
        label={showLabel ? t('selectType', { type: t('emailAccount').toLowerCase() }) : ''}
        disableClearable
        disabled={isLoading || autoCompleteEmailAccountsAreLoading || disabled}
        noOptionsText={allowUserAccounts ? t('noOptions') : t('noTeamEmailsFound')}
        size="sm"
        onChange={updateEmailAccount}
        options={emailAccountsAutocomplete ?? []}
        placeholder={t('selectMailbox')}
        value={
          selectedOption
            ? { label: selectedOption.emailAddress, value: selectedOption.linkId }
            : null
        }
      />
    </div>
  ) : null;
};

EmailAccountsAutocomplete.propTypes = propTypes;

export default EmailAccountsAutocomplete;
