import { FormGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { colorAdditional, colorBackground } from '../../../../shared/utils/palette';
import API from '../../../../shared/api/ApiService';
import CasePropTypes from '../../../../shared/prop-types/CasePropTypes';
import { sentencize } from '../../../../shared/utils/helpers';
import TextCheckbox from '../../../../shared/components/checkboxes/TextCheckbox';
import useDebounce from '../../../../shared/hooks/UseDebounce';
import useNotification from '../../../../shared/hooks/UseNotification';

const RepairRemarksContainer = styled.div`
  background-color: ${colorBackground};
  height: 100%;
  min-height: 400px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 1023px) {
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      opacity: 0;
      z-index: 0;
      transition: opacity 0.3s, z-index 0.3s 0.3s;
      background-color: ${colorAdditional};
    }

    ${(props) =>
      props.open &&
      `
      &:before {
        opacity: 0.2;
        z-index: 1;
        transition: opacity 0.3s, z-index 0s;
        pointer-events: none;
      }

      .chat__scroll, .chat__text-field {
        pointer-events: none;
      }
    `}
  }
`;

const TitleSection = styled(Typography)`
  font-size: 1rem;
  padding: 10px 20px;
`;

const CaseRepairRemarks = ({ currentCase, setHasRemarks }) => {
  const caseId = currentCase.id;
  const contractorId = currentCase.contractorId ?? '';
  const contractorName = currentCase.relations.find((r) => r.isContractor)?.displayName ?? '';
  const [contractorRemarks, setContractorRemarks] = useState();
  const [contractorRemarksChanged, setContractorRemarksChanged] = useState(false);
  const [caseRemarksChanged, setCaseRemarksChanged] = useState(false);
  const [caseRemarks, setCaseRemarks] = useState({
    caseId: currentCase.id,
    clientDelayedProcessing: currentCase.clientDelayedProcessing,
    inAccordanceWithObtainedInformation: currentCase.inAccordanceWithObtainedInformation,
  });
  const debouncedCaseRemarks = useDebounce(caseRemarks, 750);

  const debouncedContractorRemarks = useDebounce(contractorRemarks, 750);
  const { t } = useTranslation('common');
  const { sendDefaultError, sendNotification } = useNotification();

  useEffect(() => {
    if (caseRemarks == null || contractorRemarks == null) {
      return;
    }
    const hasRemarks =
      caseRemarks.clientDelayedProcessing === true ||
      caseRemarks.inAccordanceWithObtainedInformation === false ||
      contractorRemarks.caseCancelledBecauseOfContractor === true ||
      contractorRemarks.deliversEstimatesAndInvoicesCorrectly === false ||
      contractorRemarks.fillsInCase === false ||
      contractorRemarks.givesSufficientAndCorrectFeedback === false ||
      contractorRemarks.followsUpTasks === false ||
      contractorRemarks.sendsEstimatesToKeypoint === false;
    setHasRemarks(hasRemarks);
  }, [caseRemarks, contractorRemarks, setHasRemarks]);

  useEffect(() => {
    const getContractorRemarks = async () => {
      if (contractorId) {
        const response = await API.fetchGetContractorRemarksForCase(contractorId, caseId);
        if (response.serviceError == null && response.status === 200) {
          setContractorRemarks(response.data);
        }
      }
    };
    getContractorRemarks();
  }, [caseId, contractorId]);
  const onContractorRemarksChange = (prop, value) => {
    if (!contractorRemarksChanged) {
      setContractorRemarksChanged(true);
    }
    const currentRemarks = contractorRemarks;
    const updatedRemarks = { ...currentRemarks, [prop]: value };
    setContractorRemarks(updatedRemarks);
  };

  const onCaseRemarksChange = (prop, value) => {
    if (!caseRemarksChanged) {
      setCaseRemarksChanged(true);
    }
    const temp = { ...caseRemarks, [prop]: value };
    setCaseRemarks(temp);
  };

  useEffect(() => {
    const updateCaseRemarks = async () => {
      try {
        if (caseRemarksChanged) {
          const response = await API.putUpdateCaseRemarksForCase(debouncedCaseRemarks);
          if (response.serviceError != null || response.status !== 200) {
            sendDefaultError(response);
            return;
          }
          sendNotification({
            message: sentencize(
              t('common:typeSuccessfullyUpdated', { type: t('common:caseRemarks') }),
            ),
            header: t('common:success'),
            variant: 'success',
          });
        }
      } catch {
        sendDefaultError();
      }
    };
    updateCaseRemarks();
  }, [debouncedCaseRemarks, sendDefaultError, sendNotification, t]);

  useEffect(() => {
    const updateContractorRemarks = async () => {
      try {
        if (contractorRemarksChanged) {
          const response = await API.putUpdateContractorRemarksForCase(debouncedContractorRemarks);
          if (response.status === 200) {
            sendNotification({
              message: sentencize(
                t('common:typeSuccessfullyUpdated', { type: t('common:contractorRemarks') }),
              ),
              header: t('common:success'),
              variant: 'success',
            });
            return;
          }
          sendDefaultError(response);
        }
      } catch {
        sendDefaultError();
      }
    };
    updateContractorRemarks();
  }, [debouncedContractorRemarks, sendDefaultError, sendNotification, t]);

  return (
    <RepairRemarksContainer>
      {contractorRemarks && (
        <div className="mb-3">
          <TitleSection>
            {t('common:contractorRemarks')}: {contractorName}
          </TitleSection>
          <FormGroup className="mb-2">
            <TextCheckbox
              yesText={t('common:deliversEstimatesAndInvoicesCorrectly')}
              noText={t('common:doesNotDeliverEstimatesAndInvoicesCorrectly')}
              isChecked={contractorRemarks.deliversEstimatesAndInvoicesCorrectly}
              callback={(checked) =>
                onContractorRemarksChange('deliversEstimatesAndInvoicesCorrectly', checked)
              }
            />
          </FormGroup>
          <FormGroup className="mb-2">
            <TextCheckbox
              yesText={t('common:fillsInCase')}
              noText={t('common:doesNotFillInCase')}
              isChecked={contractorRemarks.fillsInCase}
              callback={(checked) => onContractorRemarksChange('fillsInCase', checked)}
            />
          </FormGroup>
          <FormGroup className="mb-2">
            <TextCheckbox
              yesText={t('common:followsUpTasks')}
              noText={t('common:doesNotFollowUpTasks')}
              isChecked={contractorRemarks.followsUpTasks}
              callback={(checked) => onContractorRemarksChange('followsUpTasks', checked)}
            />
          </FormGroup>
          <FormGroup className="mb-2">
            <TextCheckbox
              yesText={t('common:sufficientAndCorrectFeedback')}
              noText={t('common:insufficientOrInCorrectFeedback')}
              isChecked={contractorRemarks.givesSufficientAndCorrectFeedback}
              callback={(checked) =>
                onContractorRemarksChange('givesSufficientAndCorrectFeedback', checked)
              }
            />
          </FormGroup>
          <FormGroup className="mb-2">
            <TextCheckbox
              yesText={t('common:caseNotCancelledBecauseOfContractor')}
              noText={t('common:caseCancelledBecauseOfContractor')}
              isChecked={!contractorRemarks.caseCancelledBecauseOfContractor}
              callback={(checked) =>
                onContractorRemarksChange('caseCancelledBecauseOfContractor', !checked)
              }
            />
          </FormGroup>
          <FormGroup className="mb-2">
            <TextCheckbox
              yesText={t('common:sendsEstimatesToKeypoint')}
              noText={t('common:sendsEstimatesToClient')}
              isChecked={contractorRemarks.sendsEstimatesToKeypoint}
              callback={(checked) => onContractorRemarksChange('sendsEstimatesToKeypoint', checked)}
            />
          </FormGroup>
        </div>
      )}
      <div className="mb-3">
        <TitleSection>{t('common:caseRemarks')}</TitleSection>
        <FormGroup className="mb-2">
          <TextCheckbox
            yesText={t('common:caseRemarksInAccordance')}
            noText={t('common:caseRemarksNotInAccordance')}
            isChecked={currentCase?.inAccordanceWithObtainedInformation}
            callback={(checked) =>
              onCaseRemarksChange('inAccordanceWithObtainedInformation', checked)
            }
          />
        </FormGroup>
        <FormGroup className="mb-2">
          <TextCheckbox
            yesText={t('common:caseRemarksClientFollowedProcessing')}
            noText={t('common:caseRemarksClientDelayedProcessing')}
            isChecked={!currentCase?.clientDelayedProcessing}
            callback={(checked) => onCaseRemarksChange('clientDelayedProcessing', !checked)}
          />
        </FormGroup>
      </div>
    </RepairRemarksContainer>
  );
};

CaseRepairRemarks.propTypes = {
  currentCase: CasePropTypes.isRequired,
  setHasRemarks: PropTypes.func.isRequired,
};

export default CaseRepairRemarks;
