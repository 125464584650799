import * as yup from 'yup';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BuildingTagForBuilding, SimpleBuilding } from '~/common/types/building';
import { BuildingTag } from '@/types/trpc/building';
import { Nullable } from '~/common/types';

import { AppContext } from '@/shared/context/context';
import { trpc } from '@/config/trpc';
import { UserOption } from '@/types/trpc/team';

export type AddEditBuildingFormikValues = {
  addressLine: Nullable<string>;
  postalCode: Nullable<string>;
  city: Nullable<string>;
  country: Nullable<string>;
  administrators: UserOption[];
  buildingTags: BuildingTag[];
  id: Nullable<string>;
  info: Nullable<string>;
  isActive: boolean;
  name: Nullable<string>;
};

const DEFAULT_COUNTRY = 'BE';

const useAddEditBuildingFormConfig = (
  building?: SimpleBuilding,
  buildingTags?: BuildingTagForBuilding[],
) => {
  const { currentTeamUser } = useContext(AppContext);
  const { t } = useTranslation('errors');

  const { data: userOptions } = trpc.team.user.autocomplete.useQuery();

  const initialValues = (() => {
    if (building) {
      const { address, administrators, buildingId: id, info, name, ...rest } = building;

      const initialAdmins = administrators
        .map((userId) => userOptions?.find((option) => option.value === userId))
        .filter(Boolean) as UserOption[];
      const initialTags = (buildingTags ?? []).map(
        ({ id: tagId, description: tagDescription }) => ({
          label: tagDescription,
          value: tagId,
        }),
      );

      return {
        addressLine: address.addressLine || null,
        administrators: initialAdmins,
        buildingTags: initialTags,
        city: address.city || null,
        country: address.country,
        id: id || null,
        info: info || null,
        name: name || null,
        postalCode: address.postalCode || null,
        ...rest,
      };
    }

    return {
      addressLine: null,
      administrators: currentTeamUser
        ? [
            {
              data: {
                role: currentTeamUser.role,
                userId: currentTeamUser.userId,
              },
              label: currentTeamUser.name || currentTeamUser.id,
              value: currentTeamUser.id,
            },
          ]
        : [],
      buildingTags: [],
      city: null,
      country: DEFAULT_COUNTRY,
      id: null,
      info: null,
      isActive: true,
      name: null,
      postalCode: null,
    };
  })();

  return {
    initialValues,
    validationSchema: yup.object().shape(
      {
        addressLine: yup
          .string()
          .nullable()
          .when(['city', 'postalCode'], {
            is: (city: string, postalCode: string) => city || postalCode,
            then: yup.string().nullable().required(t('fieldIsRequired')),
          }),
        buildingYear: yup.number().nullable().positive(t('valueMustBePositive')),
        city: yup
          .string()
          .nullable()
          .when(['addressLine', 'postalCode'], {
            is: (addressLine: string, postalCode: string) => addressLine || postalCode,
            then: yup.string().nullable().required(t('fieldIsRequired')),
          }),
        country: yup.string().nullable(),
        info: yup.string().nullable(),
        isActive: yup.bool(),
        name: yup.string().nullable().required(t('fieldIsRequired')),
        postalCode: yup
          .string()
          .nullable()
          .when(['addressLine', 'city'], {
            is: (addressLine: string, city: string) => addressLine || city,
            then: yup.string().nullable().required(t('fieldIsRequired')),
          }),
      },
      [
        ['addressLine', 'city'],
        ['city', 'postalCode'],
        ['addressLine', 'postalCode'],
      ],
    ),
  };
};

export default useAddEditBuildingFormConfig;
