import { Autocomplete, Checkbox, FormControl, FormHelperText, FormLabel, Textarea } from '@mui/joy';
import React, { useContext, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CaseRole, ExactOnlinePaymentCondition, Language } from '~/common/enums';
import { AddEditCompanyFormikValues } from './useAddEditCompanyFormConfig';
import { PhoneNumberType } from '@/shared/components/phoneNumber/InternationalPhoneNumber';
import { TAutocompleteOption } from '~/common/types';

import { decapitalize, sentencize } from '@/shared/utils/helpers';
import { AppContext } from '@/shared/context/context';
import { ReduxStateProps } from '@/redux/reducers/types';
import { trpc } from '@/config/trpc';
import useToggle from '@/shared/hooks/UseToggle';

import ConfirmationModal from '@/shared/components/ConfirmationModal';
import EmailAddressInput from '@/containers/Email/EmailAddressInput';
import FormikBicInput from '@/shared/formik/FormikBicInput';
import FormikHomeownerAssociationSelector from '@/shared/components/2.0/formik/FormikHomeownerAssociationSelector';
import FormikIbanInput from '@/shared/formik/FormikIbanInput';
import FormikInternationalPhoneNumberInput from '@/shared/components/2.0/formik/FormikInternationalPhoneNumberInput';
import FormikTextField from '@/shared/components/2.0/formik/FormikTextField';
import FormikVatInput from '@/shared/formik/FormikVatInput';

type Props = {
  minified?: boolean;
  detailed?: boolean;
  setHasManualError?: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddEditCompanyFormBody: React.FC<Props> = ({
  detailed = false,
  minified = false,
  setHasManualError = () => {},
}) => {
  const { currentTeam } = useContext(AppContext);
  const { t } = useTranslation(['common', 'errors']);
  const { values, setFieldValue, setFieldTouched, handleChange, errors, touched } =
    useFormikContext<AddEditCompanyFormikValues>();

  const { hasActiveExactOnlineIntegration } = useSelector(
    (state: ReduxStateProps) => state.integrations,
  );

  const exactOnlinePaymentConditionOptions = Object.values(ExactOnlinePaymentCondition).map(
    (pc) => ({
      label: t(`_exact.paymentCondition.${decapitalize(pc)}`),
      value: pc,
    }),
  ) as TAutocompleteOption<ExactOnlinePaymentCondition>[];

  const languageOptions = Object.values(Language).map((l) => ({
    label: t(decapitalize(l)),
    value: l,
  })) as TAutocompleteOption<Language>[];

  const caseRoleOptions = Object.values(CaseRole).map((role) => ({
    label: t(decapitalize(role)),
    value: role,
  })) as TAutocompleteOption<CaseRole>[];

  const { data: tags } = trpc.company.tags.useQuery();

  const directDebitToggle = useToggle();

  const hasError = (name: keyof AddEditCompanyFormikValues) => !!touched[name] && !!errors[name];

  const selectedLanguage = useMemo(
    () => languageOptions.find((language) => language.value === values.language),
    [languageOptions, values.language],
  );

  const selectedCaseRole = useMemo(
    () => caseRoleOptions.find((role) => role.value === values.defaultCaseRole),
    [caseRoleOptions, values.defaultCaseRole],
  );

  useEffect(() => {
    setHasManualError(!!errors.vatNumber);
  }, [errors.vatNumber, setHasManualError]);

  const allowSyndicManagement = !!currentTeam?.allowSyndicManagement;
  const allowRepairManagement = !!currentTeam?.allowRepairManagement;
  const allowCaseManagement = !!currentTeam?.allowCaseManagement;

  return (
    <div className="flex flex-col gap-4">
      <FormikVatInput name="vatNumber" />
      <FormikTextField name="name" label={t('displayName')} required />
      <div className={allowCaseManagement ? 'flex gap-4' : ''}>
        <div className={allowCaseManagement ? 'w-1/2' : ''}>
          <FormControl>
            <FormLabel>{t('tags')}</FormLabel>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.value === value.value}
              multiple
              onChange={(_, options) => setFieldValue('companyTags', options)}
              options={tags || []}
              value={values.companyTags || []}
            />
          </FormControl>
        </div>
        {allowCaseManagement && (
          <div className="w-1/2">
            <FormControl>
              <FormLabel>{t('defaultRole')}</FormLabel>
              <Autocomplete
                options={caseRoleOptions}
                value={selectedCaseRole}
                onChange={(_, value) => {
                  setFieldValue('defaultCaseRole', value?.value || null);
                }}
              />
            </FormControl>
          </div>
        )}
      </div>
      {!minified && (
        <>
          {allowSyndicManagement && (
            <>
              <div className="flex flex-wrap items-end gap-4 *:min-w-48">
                <FormikIbanInput name="iban" label={t('iban')} />
                <FormikBicInput name="bic" label={t('bic')} />
                <FormikTextField
                  min={0}
                  name="paymentPeriod"
                  label={sentencize(`${t('paymentPeriod')} (${t('days')})`)}
                  type="number"
                />
                <div className="p-0.5">
                  <Checkbox
                    checked={values.directDebitEnabled}
                    label={t('directDebit')}
                    onChange={(e) => {
                      const { checked } = e.target;

                      if (checked) {
                        directDebitToggle.show();
                      } else {
                        setFieldValue('directDebitHomeownerAssociationIds', []);
                        setFieldValue('directDebitEnabled', checked);
                      }
                    }}
                  />
                </div>
              </div>
              {values.directDebitEnabled && (
                <div className="flex gap-4">
                  <FormikHomeownerAssociationSelector
                    label={t('homeownerAssociationsWhereDirectDebitIsEnabled')}
                    isRequired
                    name="directDebitHomeownerAssociationIds"
                    multiple
                    withSelectAllButton
                  />
                </div>
              )}
            </>
          )}

          {!allowSyndicManagement && <FormikIbanInput name="iban" label={t('iban')} />}

          {allowRepairManagement && hasActiveExactOnlineIntegration && (
            <FormControl>
              <FormLabel>{t('paymentCondition')}</FormLabel>
              <Autocomplete
                onChange={(_, value) =>
                  setFieldValue('exactOnlinePaymentCondition', value?.value || null)
                }
                value={
                  exactOnlinePaymentConditionOptions.find(
                    (option) => option.value === values.exactOnlinePaymentCondition,
                  ) || null
                }
                options={exactOnlinePaymentConditionOptions}
                name="exactOnlinePaymentCondition"
                placeholder={t('paymentCondition')}
              />
            </FormControl>
          )}

          <div className="flex gap-4">
            <div className="w-1/2">
              <FormikInternationalPhoneNumberInput
                type={PhoneNumberType.Landline}
                name="phoneNumber"
                label={t('phoneNumber')}
              />
            </div>
            <div className="w-1/2">
              <FormikInternationalPhoneNumberInput
                type={PhoneNumberType.Mobile}
                name="mobilePhoneNumber"
                label={t('mobilePhoneNumber')}
              />
            </div>
          </div>

          {detailed && allowRepairManagement && (
            <FormControl className="pb-2">
              <Checkbox
                name="isActive"
                onChange={handleChange}
                checked={values.isActive}
                label={t('active')}
              />
            </FormControl>
          )}

          <EmailAddressInput />
          <FormControl error={hasError('language')}>
            <FormLabel required>{t('language')}</FormLabel>
            <Autocomplete
              error={hasError('language')}
              required
              options={languageOptions}
              value={selectedLanguage}
              onChange={(_, value) => {
                setFieldTouched('language', true);
                setFieldValue('language', value?.value || null);
              }}
              onBlur={() => setFieldTouched('language', true)}
            />
            {hasError('language') && (
              <FormHelperText className="pl-1 text-xs">{errors.language}</FormHelperText>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>{t('note')}</FormLabel>
            <Textarea
              value={values.note || ''}
              onChange={(e) => setFieldValue('note', e.target.value)}
            />
          </FormControl>
          {detailed && (
            <FormControl>
              <Checkbox
                name="pushCrmChanges"
                onChange={handleChange}
                checked={values.pushCrmChanges}
                label={t('pushCrmChanges')}
                className="ml-auto"
              />
            </FormControl>
          )}
          <ConfirmationModal
            toggle={directDebitToggle}
            onConfirm={() => setFieldValue('directDebitEnabled', true)}
          >
            {t('directDebitWillBeTurnedOn')}
          </ConfirmationModal>
        </>
      )}
    </div>
  );
};

export default AddEditCompanyFormBody;
