import { lowerFirst } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectionOptions, SharedOptions, UseAutocompleteOptionsResult } from './types';
import useAutocompleteOptions from './useAutocompleteOptions';

type Enum<T extends string | number> = {
  [key: string]: T;
};

type Options<T extends string | number> = SharedOptions & {
  srcEnum: Enum<T>;
  translationKey?: string;
} & SelectionOptions<T>;

function useAutocompleteEnum<T extends string | number>(
  options: Options<T> & { multiple: true },
): UseAutocompleteOptionsResult<T, true>;

function useAutocompleteEnum<T extends string | number>(
  options: Options<T> & { multiple?: false },
): UseAutocompleteOptionsResult<T, false | undefined>;

function useAutocompleteEnum<T extends string | number>({
  srcEnum,
  translationKey = '',
  ...useAutocompleteOptionsProps
}: Options<T>): UseAutocompleteOptionsResult<T, typeof useAutocompleteOptionsProps.multiple> {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      Object.values(srcEnum)
        .filter((value) => value !== 'Unspecified')
        .map((value) => {
          const subKey = lowerFirst(value.toString());

          return {
            label: t(translationKey.concat(subKey)),
            value,
          };
        }),
    [srcEnum, t, translationKey],
  );

  const autocompleteProps = useAutocompleteOptions({
    data: options,
    ...useAutocompleteOptionsProps,
  });

  return autocompleteProps;
}

export default useAutocompleteEnum;
