import * as ibantools from 'ibantools';
import { FormControl, FormLabel, Input } from '@mui/joy';
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormHelperText from '../2.0/forms/FormHelperText';
import { removeWhitespaces } from '../../utils/helpers';

const BicInput = ({
  defaultValue,
  required,
  callback,
  disabled,
  label,
  classes,
  touched,
  name,
}) => {
  const [bic, setBic] = useState(defaultValue);
  const [isValidBic, setIsValidBic] = useState(true);
  const [bicError, setBicError] = useState();

  const { t } = useTranslation('common');

  const triggerCallback = (ibanValue, isValid) => {
    callback({
      bic: ibanValue,
      isValid,
    });
  };

  const onChange = (input) => {
    setBic(input);
    if (!input || input.length === 0) {
      setIsValidBic(!required);
      if (required) {
        setBicError(t('errors:fieldIsRequired'));
      } else {
        setBicError(null);
      }
      triggerCallback(input, false);
      return;
    }
    const { valid, errorCodes } = ibantools.validateBIC(removeWhitespaces(input));
    setIsValidBic(valid);
    if (valid) {
      setBicError(null);
      triggerCallback(removeWhitespaces(input), true);
      return;
    }
    if (errorCodes && errorCodes.length > 0) {
      const errorCode = ibantools.ValidationErrorsBIC[errorCodes[0]];
      const readableError = t(`errors:${errorCode}`);
      setBicError(readableError);
    }
    triggerCallback(removeWhitespaces(input), false);
  };

  return (
    <FormControl className={classNames('flex-1 grow', classes)}>
      <FormLabel required={required}>{label}</FormLabel>
      <Input
        id="bic"
        name={name}
        type="text"
        value={bic}
        onChange={({ target }) => onChange(target.value)}
        error={!isValidBic}
        disabled={disabled}
      />
      {!!bicError && !!touched && (
        <FormHelperText className="px-1 text-xs">{bicError}</FormHelperText>
      )}
    </FormControl>
  );
};

BicInput.propTypes = {
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  callback: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  classes: PropTypes.string,
  touched: PropTypes.bool,
  name: PropTypes.string,
};

BicInput.defaultProps = {
  defaultValue: null,
  required: false,
  callback: () => {},
  disabled: false,
  label: null,
  classes: '',
  touched: false,
  name: 'bic',
};

export default BicInput;
