import { Nullable } from '~/common/types';

export type TeamWithAccess = {
  id: string;
  name: Nullable<string>;
};

export type File = {
  contentType: Nullable<string>;
  extension: Nullable<string>;
  fileName: Nullable<string>;
  fileSize: Nullable<number>;
  fileUri: Nullable<string>;
  url: Nullable<string>;
  id: string;
  fileId?: string;
  isPublic: boolean;
  ownerTeamId: Nullable<string>;
  readOnly: boolean;
  seen: Nullable<boolean>;
  teamsWithAccess: Nullable<TeamWithAccess[]>;
  type: Nullable<string>;
  uploadDate: Date;
  uploadedBy: Nullable<string>;
};

export const isUploadedFile = (attachment: unknown): attachment is File =>
  !!(attachment as File).uploadDate;

export enum ContentType {
  PNG = 'image/png',
  JPG = 'image/jpeg',
  WORD = 'application/msword',
  EXCEL = 'application/vnd.ms-excel',
  PDF = 'application/pdf',
  ZIP = 'application/zip',
  WAV = 'audio/wav',
  TXT = 'text/plain',
  RAR = 'application/vnd.rar',
  MP3 = 'audio/mpeg',
  MP4 = 'video/mp4',
  JSON = 'application/json',
}

// ! no response in docs
export type FileManagerFile = {
  contentType: string;
  dateCreated: string;
  dateModified: string;
  fileId: string;
  fileSystemEntityId: string;
  filterPath: string;
  hasChild: boolean;
  idForImageUrl: Nullable<string>;
  isFile: boolean;
  multipleFiles: boolean;
  name: string;
  showOnPortal: boolean;
  size: number;
  type: string;
};

export const isFileManagerFile = (attachment: unknown): attachment is FileManagerFile => {
  const fileManagerFile = attachment as FileManagerFile;

  return !!fileManagerFile.fileId && !!fileManagerFile.fileSystemEntityId;
};

export type PreviewDocument = {
  contentType?: Nullable<string>;
  fileId: string;
  fileName: string;
};
