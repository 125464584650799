import { useTranslation } from 'react-i18next';

import { Company, CompanySummary } from '~/common/types/company/company';
import { AddEditCompanyFormikValues } from './useAddEditCompanyFormConfig';
import { Language } from '~/common/enums';

import { trpc } from '@/config/trpc';
import { useCreateCompany } from '@/mutations';
import useNotification from '@/shared/hooks/UseNotification';
import useToggle from '@/shared/hooks/UseToggle';

import AddEditCompanyForm from './AddEditCompanyForm';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';

type Props = {
  company?: Company | CompanySummary;
  isContractor?: boolean;
  toggle: ReturnType<typeof useToggle>;
  onSuccess?: () => void;
};

const AddEditCompanyModal: React.FC<Props> = ({
  company,
  isContractor = false,
  toggle,
  onSuccess = () => {},
}) => {
  const { t } = useTranslation();
  const { sendSuccessMessage, sendDefaultError, sendNotification } = useNotification();

  const { mutateAsync: addCompanyAsync, isLoading: isAddingCompany } = useCreateCompany();
  const { mutateAsync: updateCompanyAsync, isLoading: isUpdatingCompany } =
    trpc.company.update.useMutation();

  const type = t('company');

  const onSubmit = async (values: AddEditCompanyFormikValues) => {
    const {
      language,
      phoneNumber,
      mobilePhoneNumber,
      name,
      companyId,
      companyTags,
      directDebitEnabled,
      ...rest
    } = values;

    const requestBody = {
      companyId: companyId || '',
      companyTags: companyTags.map((tag) => tag.value),
      directDebit: directDebitEnabled,
      language: language || Language.English,
      mobilePhoneNumber: {
        countryCode: mobilePhoneNumber?.countryCode,
        number: mobilePhoneNumber?.number,
      },
      name: name || '',
      phoneNumber: {
        countryCode: phoneNumber?.countryCode,
        number: phoneNumber?.number,
      },
      ...rest,
    };

    try {
      let message;

      if (requestBody.companyId) {
        message = t('typeSuccessfullyUpdated', { type });

        await updateCompanyAsync(requestBody);
      } else {
        message = t('typeSuccessfullyAdded', { type });

        await addCompanyAsync(requestBody);
      }

      sendSuccessMessage(message);
      onSuccess();
      toggle.hide();
    } catch (error: any) {
      if (error?.data?.httpStatus === 409) {
        sendNotification({
          header: t('errors:conflict'),
          message: t('errors:companyWithVatNumberAlreadyExists', {
            vatNumber: requestBody.vatNumber,
          }),
          variant: 'error',
        });
        return;
      }
      sendDefaultError(error);
    }
  };

  const title = (() => {
    if (company?.id) {
      return t('updateType', { type });
    }

    return t('addType', { type });
  })();

  return (
    <KpcModal size="w-3/4 2xl:w-1/2" title={title} toggle={toggle}>
      <AddEditCompanyForm
        onSubmit={onSubmit}
        onCancel={toggle.hide}
        company={company}
        isContractor={isContractor}
        isSubmitting={isAddingCompany || isUpdatingCompany}
      />
    </KpcModal>
  );
};

export default AddEditCompanyModal;
