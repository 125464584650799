import { useMemo } from 'react';

import { SelectionOptions, SharedOptions, UseAutocompleteOptionsResult } from './types';
import { HomeownerAssociationAccountOption } from '@/types/trpc/hoa';

import { GeneralLedgerAccountType, ProvisionType } from '~/common/enums';
import { getDefaultAccountTranslation } from '@/shared/utils/hoa/hoaUtils';
import { trpc } from '@/config/trpc';
import useAutocompleteOptions from './useAutocompleteOptions';

const glaTypeMapping = {
  [ProvisionType.FixedWorkCapital]: GeneralLedgerAccountType.FixedWorkCapital,
  [ProvisionType.SpareCapital]: GeneralLedgerAccountType.ReserveCapital,
  [ProvisionType.WorkCapital]: GeneralLedgerAccountType.WorkCapital,
};

type HoaOptionExtension = Pick<HomeownerAssociationAccountOption, 'data'>;

type Options = SharedOptions & {
  homeownerAssociationId: string | null | undefined;
  startsWith?: string[];
  provisionType?: ProvisionType;
} & SelectionOptions<string, HoaOptionExtension>;

function useHoaAccountAutocomplete(
  options: Options & { multiple: true },
): UseAutocompleteOptionsResult<string, true, HoaOptionExtension>;

function useHoaAccountAutocomplete(
  options: Options & { multiple?: false },
): UseAutocompleteOptionsResult<string, false | undefined, HoaOptionExtension>;

function useHoaAccountAutocomplete({
  homeownerAssociationId,
  startsWith = [],
  provisionType,
  ...useAutocompleteOptionsProps
}: Options): UseAutocompleteOptionsResult<
  string,
  typeof useAutocompleteOptionsProps.multiple,
  HoaOptionExtension
> {
  const { data: options, isLoading } = trpc.hoa.account.autocomplete.useQuery(
    {
      homeownerAssociationId: homeownerAssociationId as string,
      startsWith: startsWith || [],
    },
    { enabled: !!homeownerAssociationId },
  );

  const translatedOptions = useMemo(() => {
    if (!options) return [];

    const translateLabel = (option: HomeownerAssociationAccountOption) => {
      const { label } = option;
      const { defaultAccount, number } = option.data;

      return getDefaultAccountTranslation(label, number, defaultAccount);
    };

    return options
      .filter((o) => !provisionType || o.data.type === glaTypeMapping[provisionType])
      .map((option) => ({
        ...option,
        label: translateLabel(option),
      }));
  }, [provisionType, options]);

  const autocompleteProps = useAutocompleteOptions({
    data: translatedOptions,
    isLoading,
    ...useAutocompleteOptionsProps,
  });

  return {
    ...autocompleteProps,
    disabled: !homeownerAssociationId || autocompleteProps.disabled,
  };
}

export default useHoaAccountAutocomplete;
