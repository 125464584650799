import { Autocomplete, FormControl, FormLabel, IconButton, Tooltip } from '@mui/joy';
import { FormikValues, useFormikContext } from 'formik';
import { orderBy, uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { CaseType, EntityType, RelationType } from '~/common/enums';
import { AddEditCompanyFormikValues } from '@/containers/Companies/AddEditCompany/useAddEditCompanyFormConfig';
import { CreateCaseValues } from './AddCase';
import { RelationOption } from '@/types/trpc/case';
import { RouteParams } from '@/types';

import { queryIds } from '@/shared/utils/constants';
import { trpc } from '@/config/trpc';
import useToggle from '@/shared/hooks/UseToggle';

import { CompanyIcon, ContactIcon, InlineSpinner } from '@/shared/icons/Icons';
import { useCreateCompany, useCreateContact } from '@/mutations';
import AddEditCompanyForm from '@/containers/Companies/AddEditCompany/AddEditCompanyForm';
import AddEditContactForm from '@/containers/Contacts/AddEditContact/AddEditContactForm';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';
import VirtualListBoxAdapter from '@/shared/components/2.0/adapters/VirtualListBoxAdapter';

type Option = RelationOption & {
  isSuggestion: boolean;
};

type FieldKey = 'clients' | 'requestor';

type Props = {
  callbacks: {
    onError: (error: unknown) => void;
    onSuccess: (message: string, queryKey: string) => void;
  };
  caseType: CaseType;
  multiple?: boolean;
  name: FieldKey;
  label: string;
  required?: boolean;
};

const ContactCompanyAutocomplete: React.FC<Props> = ({
  callbacks,
  caseType,
  name,
  multiple = false,
  label,
  required = false,
}) => {
  const [options, setOptions] = useState<Option[]>([]);

  const { t } = useTranslation();
  const { caseId } = useParams() as RouteParams;
  const { values, setFieldValue } = useFormikContext<CreateCaseValues>();

  const companyToggle = useToggle<FieldKey>();
  const contactToggle = useToggle<FieldKey>();

  const isComplaint = caseType === CaseType.Complaint;

  const value = (() => {
    const v = values[name];

    if (multiple) return v || [];

    return v || null;
  })();

  const { data: caseData, isLoading: caseDataIsLoading } = trpc.case.byId.useQuery(caseId, {
    enabled: isComplaint,
  });
  const { data: relationOptions, isLoading: relationOptionsAreLoading } =
    trpc.case.relationOptions.useQuery();

  const { mutateAsync: createCompanyAsync, isLoading: isCreatingCompany } = useCreateCompany();
  const { mutateAsync: createContactAsync, isLoading: isCreatingContact } = useCreateContact();

  const suggestedRelationIds = useMemo(() => {
    if (!caseData) return [];

    const relationIds = caseData.relations
      .map(({ relationType, contactId, companyId, teamId }) => {
        if (relationType === RelationType.Company) return companyId;
        if (relationType === RelationType.Contact) return contactId;

        return teamId;
      })
      .filter(Boolean) as string[];

    return uniq(relationIds);
  }, [caseData]);

  const submitContact = async (contactValues: FormikValues) => {
    if (!contactToggle.state) return;

    try {
      const { addressLine, postalCode, city, country, ...data } = contactValues;

      const contactResponse = await createContactAsync({
        ...data,
        address: {
          addressLine,
          city,
          country,
          postalCode,
        },
      });

      const { firstName, lastName, id, ...contact } = contactResponse;

      const newContactOption = {
        entity: EntityType.Contact,
        isSuggestion: false,
        label: `${firstName || ''} ${lastName || ''}`.trim(),
        type: RelationType.Contact,
        value: id,
        ...contact,
      };

      setFieldValue(
        contactToggle.state,
        multiple && Array.isArray(value) ? [...value, newContactOption] : newContactOption,
      );
      setOptions((prevValues) => [...prevValues, newContactOption]);
      contactToggle.hide();
      callbacks.onSuccess('contact', queryIds.contacts.GET_CONTACTS);
    } catch (error) {
      callbacks.onError(error);
    }
  };

  const submitCompany = async (companyValues: AddEditCompanyFormikValues) => {
    if (!companyToggle.state) return;

    try {
      const companyResponse = await createCompanyAsync(companyValues);

      const { vatNumber, displayName, id, emailAddresses, ...company } = companyResponse;

      const newCompanyOption = {
        address: {
          country: 'BE' as const,
          postalCode: null,
        },
        displayName: displayName || id,
        emailAddresses: emailAddresses || [],
        entity: EntityType.Company,
        id,
        isSuggestion: false,
        label: vatNumber ? `${displayName} (${vatNumber})` : displayName || id,
        type: RelationType.Company,
        value: id,
        ...company,
      };

      setFieldValue(
        companyToggle.state,
        multiple && Array.isArray(value) ? [...value, newCompanyOption] : newCompanyOption,
      );
      setOptions((prevValues) => [...prevValues, newCompanyOption]);
      companyToggle.hide();
      callbacks.onSuccess('company', queryIds.companies.GET_COMPANIES);
    } catch (error) {
      callbacks.onError(error);
    }
  };

  useEffect(() => {
    if (!relationOptions) return;

    const groupedRelations = relationOptions.map((option) => {
      const { id } = option;

      const isSuggestion = !!id && suggestedRelationIds.includes(id);

      return {
        ...option,
        isSuggestion,
      };
    });
    const orderedRelations = orderBy(groupedRelations, ['isSuggestion'], ['desc']);

    setOptions(orderedRelations);
  }, [relationOptions, suggestedRelationIds]);

  const createActions = (key: FieldKey) => [
    {
      icon: <ContactIcon />,
      key: 'createContact',
      onClick: () => contactToggle.show(key),
      tooltip: t('_contact.actions.create'),
    },
    {
      icon: <CompanyIcon />,
      key: 'createCompany',
      onClick: () => companyToggle.show(key),
      tooltip: t('_company.actions.create'),
    },
  ];

  const optionsAreLoading = (isComplaint && caseDataIsLoading) || relationOptionsAreLoading;

  return (
    <>
      <FormControl>
        <FormLabel required={required}>{label}</FormLabel>
        <div className="flex gap-3">
          <Autocomplete
            multiple={multiple}
            className="flex-grow"
            placeholder={t('search.placeholder')}
            disableCloseOnSelect={multiple}
            options={options}
            value={value as Option | Option[] | null}
            isOptionEqualToValue={(option, val) => option.value === val.value}
            onChange={(_, v) => setFieldValue(name, v)}
            startDecorator={optionsAreLoading ? <InlineSpinner /> : null}
            slots={{ listbox: VirtualListBoxAdapter }}
            groupBy={(option) => (option.isSuggestion ? t('suggestions') : t('options'))}
            renderOption={(props, option) => [props, option] as React.ReactNode}
            renderGroup={(params) => params as unknown as React.ReactNode}
          />
          {createActions(name).map(({ tooltip, onClick, icon }) => (
            <Tooltip key={tooltip} title={tooltip}>
              <IconButton onClick={onClick}>{icon}</IconButton>
            </Tooltip>
          ))}
        </div>
      </FormControl>
      {companyToggle.value && (
        <KpcModal title={t('company')} toggle={companyToggle}>
          <AddEditCompanyForm
            isSubmitting={isCreatingCompany}
            onCancel={companyToggle.hide}
            onSubmit={submitCompany}
          />
        </KpcModal>
      )}

      {contactToggle.value && (
        <KpcModal title={t('contact')} toggle={contactToggle}>
          <AddEditContactForm
            isSubmitting={isCreatingContact}
            onCancel={contactToggle.hide}
            onSubmit={submitContact}
          />
        </KpcModal>
      )}
    </>
  );
};

export default ContactCompanyAutocomplete;
