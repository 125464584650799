import { FormControl, FormHelperText, FormLabel, Input } from '@mui/joy';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ClearIcon, EuroIcon } from '../../icons/Icons';
import { formatAsCurrency, isDecimalNumber } from '../../utils/helpers';
import IconButton from '../buttons/IconButton';

// default for Europe, at some time this may even be stored in the team settings
const DEFAULT_CURRENCY = '€';
// default for currency, can be overwriten for some specific usecases
const DEFAULT_DIGITS = 2;

const CurrencyInput = ({
  label,
  currency,
  currencyValue,
  callback,
  required,
  disabled,
  digits,
  position,
  fullWidth,
  errorText,
  autoFocus,
  allowNull,
  allowNegative,
}) => {
  const { t } = useTranslation(['common', 'errors']);
  const [currencySymbol, setCurrencySymbol] = useState();
  const [value, setValue] = useState(0);
  const [isValidCurrency, setIsValidCurrency] = useState(true);
  const [currencyError, setCurrencyError] = useState('');
  const [amountOfDigits, setAmountOfDigits] = useState();

  useEffect(() => {
    setValue(
      currencyValue != null
        ? formatAsCurrency(currencyValue, false, digits ?? DEFAULT_DIGITS)
        : null,
    );
  }, [currencyValue, digits]);

  useEffect(() => {
    // currencySymbol could be set by the parent component based on the passed currency,
    // in the future this may be needed, even though its is not necessary at this time.
    // this is why we just set the default: Euro (€)
    setCurrencySymbol(DEFAULT_CURRENCY);
  }, [currency]);

  useEffect(() => {
    setAmountOfDigits(digits ?? DEFAULT_DIGITS);
  }, [digits]);

  // input is a string
  const onChange = (input) => {
    if (input == null) {
      setIsValidCurrency(!required);
      setCurrencyError(required ? 'fieldIsRequired' : null);
      callback(null);
      setValue('');
      return;
    }

    // clean input from decimal seperators & non numeric chars

    const regEx = allowNegative ? /[^.0-9-]/g : /\D/g;
    const cleanedInput = input.replaceAll('.', '').replace(',', '').replace(regEx, '');

    if (cleanedInput === '-' && allowNegative) {
      setIsValidCurrency(required);
      setCurrencyError(required ? 'fieldIsRequired' : null);
      callback(-0);
      setValue(-0);
    }

    if (!cleanedInput || !cleanedInput.length) {
      setIsValidCurrency(!required);
      setCurrencyError(required ? 'fieldIsRequired' : null);
      callback(null);
      setValue('');
      return;
    }

    // alter into decimal, with 2 floating points
    const inputDecimalAsString = (parseFloat(cleanedInput) / 10 ** amountOfDigits).toString();
    if (isDecimalNumber(inputDecimalAsString, amountOfDigits)) {
      setCurrencyError(null);
      setIsValidCurrency(true);
      callback(parseFloat(inputDecimalAsString));
      // force rendering of new value
      setValue(formatAsCurrency(inputDecimalAsString, false, amountOfDigits));
      return;
    }

    // shouldn't be possible, but just to be safe
    setCurrencyError('invalidNumber');
    setIsValidCurrency(false);
  };

  return (
    <FormControl error={!isValidCurrency || !!errorText}>
      <FormLabel required={required}>{label ?? t('common:amount')}</FormLabel>
      <Input
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        id="currency"
        name="currency"
        type="text"
        value={value ?? ''}
        onChange={({ target }) => onChange(target.value)}
        disabled={disabled}
        startDecorator={<EuroIcon className={errorText ? 'text-danger' : ''} />}
        endDecorator={
          <IconButton
            onClick={() => onChange(allowNull ? null : '0')}
            iconComponent={<ClearIcon />}
          />
        }
      />
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};

CurrencyInput.propTypes = {
  allowNegative: PropTypes.bool,
  allowNull: PropTypes.bool,
  autoFocus: PropTypes.bool,
  callback: PropTypes.func,
  currency: PropTypes.string,
  currencyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  digits: PropTypes.number,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  position: PropTypes.string,
  required: PropTypes.bool,
};

CurrencyInput.defaultProps = {
  allowNegative: false,
  allowNull: true,
  autoFocus: false,
  callback: () => {},
  currency: '',
  currencyValue: null,
  digits: null,
  disabled: false,
  errorText: null,
  fullWidth: false,
  label: '',
  position: 'start',
  required: false,
};

export default CurrencyInput;
