import { Address, Nullable } from '..';
import { CompanyOverviewResponse, CompanyResponse } from '~/backend/services/company';
import { BicValue } from '~/common/enums';

export type CompanySummary = CompanyOverviewResponse;

export type Company = CompanyResponse;

export const isFullCompany = (company: CompanySummary | Company): company is Company =>
  (company as Company).directDebitHomeownerAssociationIds !== undefined;

export type Team = {
  teamId: string;
  name: string;
  canHandleInsuranceCases: boolean;
  canReceiveInsuranceCasesFromCurrentTeam: boolean;
};

export type CompanyTeam = {
  id: string;
  centralCompanyId: string;
  companyName: string;
  teams: Team[];
};

export type CompanyPublicData = {
  address: string;
  vatNumber: string;
  name: string;
  isValid: boolean;
  message: string;
  centralCompanyId: string;
  isKnownByKeypoint: boolean;
};

export type ExternalCompany = {
  address: Address;
  companyName: string;
  externalId: string;
  internalId: Nullable<number>;
};

export type CompanyImport = {
  id: string;
  teamId: string;
  fileName: string;
  createdBy: string;
  created: Date;
  importStartedDate: Date;
  importReadyDate: Date;
  isReady: boolean;
  isBusyImporting: boolean;
  failureReason: Nullable<string>;
  fileUri: Nullable<string>;
};

export type CompanySummaryResponse = {
  id: string;
  name: string;
  vatNumber: Nullable<string>;
  iban: Nullable<string>;
  bic: Nullable<BicValue>;
};

export type CreateCompanyImportRequest = {
  file: File;
};
