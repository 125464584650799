const enum EntityType {
  Batch = 'Batch',
  BatchMessage = 'BatchMessage',
  Blog = 'Blog',
  Booking = 'Booking',
  Building = 'Building',
  BuildingUnit = 'BuildingUnit',
  Calendar = 'Calendar',
  Case = 'Case',
  CaseTemplate = 'CaseTemplate',
  CentralCompany = 'CentralCompany',
  ClosedPeriod = 'ClosedPeriod',
  Company = 'Company',
  Contact = 'Contact',
  Contract = 'Contract',
  Contractor = 'Contractor',
  ContractorSkillDefinition = 'ContractorSkillDefinition',
  CreditNote = 'CreditNote',
  DefaultGeneralLedgerAccount = 'DefaultGeneralLedgerAccount',
  EmailAccount = 'EmailAccount',
  Entity = 'Entity',
  Export = 'Export',
  ExportTemplate = 'ExportTemplate',
  FinancialManagementSetting = 'FinancialManagementSetting',
  FsmaNumber = 'FsmaNumber',
  GlobalSetting = 'GlobalSetting',
  HomeownerAssociation = 'HomeownerAssociation',
  Import = 'Import',
  MailboxFolder = 'MailboxFolder',
  MailboxLabel = 'MailboxLabel',
  ManualCorrection = 'ManualCorrection',
  Meeting = 'Meeting',
  MeetingSetting = 'MeetingSetting',
  MeetingTask = 'MeetingTask',
  MeetingInvite = 'MeetingInvite',
  MeetingType = 'MeetingType',
  MeetingProcuration = 'MeetingProcuration',
  MessageTemplate = 'MessageTemplate',
  Payment = 'Payment',
  Policy = 'Policy',
  PolicyImport = 'PolicyImport',
  SalesInvoice = 'SalesInvoice',
  SmsAccount = 'SmsAccount',
  Spreadsheet = 'Spreadsheet',
  Tag = 'Tag',
  Task = 'Task',
  TaskType = 'TaskType',
  Team = 'Team',
  User = 'User',
  Webhook = 'Webhook',
  GeneralLedgerAccount = 'GeneralLedgerAccount',
  InboundHoaInvoice = 'InboundHoaInvoice',
  AccountingStatements = 'AccountingStatements',
  Envelopes = 'Envelopes',
  ProvisionPaymentRequest = 'ProvisionPaymentRequest',
}

export default EntityType;
