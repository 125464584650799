import {
  Autocomplete,
  AutocompleteOption,
  Avatar,
  FormControl,
  FormLabel,
  Option,
  Select,
  Typography,
} from '@mui/joy';
import classNames from 'classnames';
import { DateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import CaseChannel from '@/shared/enums/CaseChannel';
import { CaseType } from '~/common/enums';
import { FormikValues } from './AddRepairCaseStepper';
import Priority from '@/shared/enums/Priority';

import { decapitalize, getAvatarInitials } from '@/shared/utils/helpers';
import { AppContext } from '@/shared/context/context';
import { trpc } from '@/config/trpc';

import useDayjs from '@/shared/hooks/useDayjs';

import DatePicker from '@/shared/components/2.0/DatePicker';
import HeaderStep from './HeaderStep';

const caseChannels = Object.values(CaseChannel);
const priorities = Object.values(Priority);

const CaseInfoStep = () => {
  const { t } = useTranslation();
  const { currentTeam } = useContext(AppContext);
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const { formatUtcDateTime } = useDayjs();

  const { data: classifications } = trpc.case.classifications.useQuery({
    caseType: CaseType.Repair,
  });
  const { data: teamUsers } = trpc.team.user.list.useQuery();

  const classificationClassName = classNames('', {
    'col-span-2': currentTeam?.allowRepairManagement,
  });

  return (
    <>
      <HeaderStep
        title={t('repairCaseForm.caseInfo.title')}
        description={t('repairCaseForm.caseInfo.description')}
        tooltipMessage={t('repairCaseForm.caseInfo.tooltip')}
      />

      <div className="grid grid-cols-2 gap-4">
        <FormControl>
          <FormLabel required>{t('channel')}</FormLabel>
          <Select
            value={values.channel}
            placeholder={t('select.placeholder')}
            onChange={(_e, option) => {
              setFieldValue('channel', option);
            }}
          >
            {caseChannels.length &&
              caseChannels.map((channel) => (
                <Option key={channel} value={channel}>
                  {t(decapitalize(channel))}
                </Option>
              ))}
          </Select>
        </FormControl>

        <FormControl className="w-full">
          <FormLabel required>{t('priority')}</FormLabel>
          <Select
            value={values.priority}
            onChange={(_e, value) => {
              setFieldValue('priority', value);
            }}
          >
            {priorities.map((priority) => (
              <Option key={priority} value={priority}>
                {t(decapitalize(priority))}
              </Option>
            ))}
          </Select>
        </FormControl>

        <DatePicker
          label={t('incident')}
          value={values.incidentDate}
          onChange={(_, dateString) => setFieldValue('incidentDate', dateString)}
        />

        {currentTeam?.allowRepairManagement && (
          <FormControl>
            <FormLabel required> {t('dateAndTimeOfReceipt')}</FormLabel>
            <DateTimePicker
              ampm={false}
              PopperProps={{ placement: 'top-start' }}
              InputProps={{ className: 'h-8 text-sm rounded-md' }}
              value={values.notificationDate}
              onChange={(value) => {
                if (value) {
                  const utcDate = formatUtcDateTime(value.toString());
                  setFieldValue('notificationDate', utcDate);
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
        )}

        <div className={classificationClassName}>
          <FormControl>
            <FormLabel>{t('classification')}</FormLabel>
            <Autocomplete
              value={classifications?.find((c) => c.id === values.classificationId)}
              placeholder={t('typeHere')}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.description || '-'}
              options={classifications || []}
              onChange={(_e, option) => {
                if (option) {
                  setFieldValue('classificationId', option.id);
                }
              }}
            />
          </FormControl>
        </div>
      </div>

      <FormControl>
        <FormLabel>{t('administrators')}</FormLabel>
        <Autocomplete
          multiple
          value={values.admins.map((id) => teamUsers?.find((user) => user.id === id))}
          disableCloseOnSelect
          placeholder={t('typeHere')}
          options={teamUsers || []}
          getOptionLabel={(option) => option?.name || '-'}
          onChange={(_e, options) => {
            setFieldValue(
              'admins',
              options.map((option) => option?.id),
            );
          }}
          renderOption={(props, option) => (
            <AutocompleteOption {...props}>
              <Typography
                startDecorator={<Avatar size="sm">{getAvatarInitials(option?.name)}</Avatar>}
              >
                {option?.name}
              </Typography>
            </AutocompleteOption>
          )}
        />
      </FormControl>
    </>
  );
};

export default CaseInfoStep;
