import {
  Autocomplete,
  AutocompleteOption,
  Checkbox,
  Chip,
  ChipDelete,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
  Tooltip,
} from '@mui/joy';
import { useFormikContext } from 'formik';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AddEditBuildingFormikValues } from './AddEditBuildingFormConfig';

import { trpc } from '@/config/trpc';

import { CancelIcon, InlineSpinner } from '@/shared/icons/Icons';
import AvatarWithColor from '@/shared/components/2.0/AvatarWithColor';
import FormikAddressForm from '../../../shared/formik/FormikAddressForm';
import FormikVatInput from '@/shared/formik/FormikVatInput';
import FormikYearPicker from '@/shared/formik/FormikYearPicker';
import { Nullable } from '~/common/types';

const TAGS_LIMIT = 6;

type Props = {
  isEdit?: boolean;
  minified?: boolean;
};

const AddEditBuildingFormBody: React.FC<Props> = ({ isEdit = false, minified = false }) => {
  const prevCompanyName = useRef<Nullable<string>>(null);

  const { t } = useTranslation();
  const { setFieldValue, setFieldTouched, values, touched, errors } =
    useFormikContext<AddEditBuildingFormikValues>();

  const { data: buildingTags, isLoading: buildingTagsAreLoading } = trpc.building.tags.useQuery();
  const { data: users, isLoading: usersAreLoading } = trpc.team.user.autocomplete.useQuery();

  const hasError = (key: keyof typeof values) => !!touched[key] && !!errors[key];

  return (
    <div className="flex flex-col gap-4">
      {!isEdit && (
        <FormControl>
          <FormLabel>{t('tags')}</FormLabel>
          <Autocomplete
            multiple
            value={values.buildingTags}
            options={buildingTags || []}
            onChange={(_, options) => setFieldValue('buildingTags', options)}
            startDecorator={buildingTagsAreLoading ? <InlineSpinner /> : null}
          />
        </FormControl>
      )}
      <FormikVatInput
        name="vatNumber"
        callback={({ companyName }) => {
          if (!values.name && companyName && companyName !== prevCompanyName.current) {
            setFieldValue('name', companyName);
          }

          prevCompanyName.current = companyName;
        }}
      />
      <FormControl error={hasError('name')}>
        <FormLabel required>{t('name')}</FormLabel>
        <Input
          value={values.name || ''}
          onChange={(e) => {
            setFieldTouched('name');
            setFieldValue('name', e.target.value);
          }}
          onBlur={() => setFieldTouched('name')}
        />
        {hasError('name') && (
          <FormHelperText className="px-1 text-xs">{errors.name}</FormHelperText>
        )}
      </FormControl>
      {isEdit && (
        <FormControl>
          <Checkbox
            label={t('active')}
            checked={values.isActive}
            onChange={(e) => setFieldValue('isActive', e.target.checked)}
          />
        </FormControl>
      )}
      {isEdit && <FormikYearPicker name="buildingYear" label={t('buildingYear')} />}
      <FormikAddressForm />
      {!minified && (
        <FormControl>
          <FormLabel>{t('administrators')}</FormLabel>
          <Autocomplete
            multiple
            value={values.administrators}
            options={users || []}
            onChange={(_, options) => setFieldValue('administrators', options)}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderTags={(tags) =>
              tags.map(({ label, value }) => (
                <Tooltip title={label} arrow placement="top-start" key={value}>
                  <Chip
                    className="rounded-full p-1"
                    variant="soft"
                    color="neutral"
                    endDecorator={
                      <ChipDelete
                        className="mx-1"
                        variant="soft"
                        onClick={() =>
                          setFieldValue(
                            'administrators',
                            values.administrators.filter((admin) => admin.value !== value),
                          )
                        }
                      >
                        <CancelIcon className="h-5 opacity-50 hover:opacity-100" />
                      </ChipDelete>
                    }
                  >
                    <AvatarWithColor name={label} className="mr-1" />
                  </Chip>
                </Tooltip>
              ))
            }
            renderOption={(props, option) => (
              <AutocompleteOption key={option.value} {...props} className="p-4">
                <AvatarWithColor name={option.label} />
                {option.label}
              </AutocompleteOption>
            )}
            startDecorator={usersAreLoading ? <InlineSpinner /> : null}
            className="min-h-12"
            limitTags={TAGS_LIMIT}
          />
          <FormHelperText />
        </FormControl>
      )}
      <FormControl>
        <FormLabel>{t('info')}</FormLabel>
        <Textarea
          value={values.info || ''}
          onChange={(e) => setFieldValue('info', e.target.value)}
        />
      </FormControl>
    </div>
  );
};

export default AddEditBuildingFormBody;
