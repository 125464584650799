import { createElement } from 'react';
import PropTypes from 'prop-types';

import { isNullOrEmpty } from '@/shared/utils/helpers';

const propTypes = {
  column: PropTypes.shape({
    customCellComponent: PropTypes.func,
  }).isRequired,
  value: PropTypes.string.isRequired,
};

const DefaultTableCell = (props) => {
  const {
    column: { customCellComponent },
    value,
  } = props;

  if (customCellComponent) {
    return createElement(customCellComponent, props);
  }
  return <div className="break-words">{isNullOrEmpty(value) ? '-' : value}</div>;
};

DefaultTableCell.propTypes = propTypes;

export default {
  DefaultTableCell,
};
