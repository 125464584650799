import { FormControl, FormLabel, Input } from '@mui/joy';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { search } from 'ka-table/actionCreators';
import { useTranslation } from 'react-i18next';

import { useSearchFilter } from '../../hooks/UseFilters';

import { ClearIcon, LoadingIcon, SearchIcon } from '../../icons/Icons';
import IconButton from '../buttons/IconButton';
import { Nullable } from '~/common/types';

type Props = {
  dispatch?: (action: unknown) => void;
  initialValue?: string;
  isFetching?: boolean;
  label?: string;
  onChange?: (value: string) => void;
  onEnter?: () => void;
  placeholder?: string;
  className?: string;
};

const Search: React.FC<Props> = ({
  dispatch,
  label,
  onChange,
  onEnter,
  initialValue = '',
  isFetching = false,
  placeholder,
  className = null,
}) => {
  const [previousInitialValue, setPreviousInitialValue] = useState('');
  const [initialValueHasBeenSet, setInitialValueHasBeenSet] = useState(false);

  const inputRef = useRef<Nullable<HTMLInputElement>>(null);

  const { debounceSearch, search: searchString } = useSearchFilter({ defaultSearch: initialValue });
  const { t } = useTranslation();

  const handleChange = useCallback(
    (value: string) => {
      if (dispatch) {
        debounceSearch(value);
      } else {
        onChange?.(value);
      }
    },
    [debounceSearch, dispatch, onChange],
  );

  useEffect(() => {
    if (!initialValueHasBeenSet || initialValue === previousInitialValue) {
      return;
    }

    setInitialValueHasBeenSet(false);
  }, [initialValue, initialValueHasBeenSet, previousInitialValue]);

  useEffect(() => {
    if (initialValueHasBeenSet || !inputRef.current) {
      return;
    }

    inputRef.current.value = initialValue;

    setInitialValueHasBeenSet(true);
    setPreviousInitialValue(initialValue);
    handleChange(initialValue);
  }, [handleChange, initialValue, initialValueHasBeenSet, previousInitialValue]);

  useEffect(() => {
    if (dispatch) {
      const action = search(searchString);

      dispatch(action);
    }
  }, [dispatch, searchString]);

  const handleClear = () => {
    if (!inputRef.current) {
      return;
    }

    const value = '';

    inputRef.current.value = value;

    handleChange(value);
  };

  return (
    <FormControl className={className ?? 'flex-1'} size="sm">
      <FormLabel>{label}</FormLabel>
      <Input
        name="search"
        slotProps={{ input: { ref: inputRef } }}
        startDecorator={<SearchIcon />}
        placeholder={placeholder ?? t('common:search.placeholder')}
        onChange={(e) => handleChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onEnter?.();
          }
        }}
        endDecorator={
          isFetching ? (
            <IconButton iconComponent={<LoadingIcon />} />
          ) : (
            <IconButton onClick={handleClear} iconComponent={<ClearIcon />} />
          )
        }
      />
    </FormControl>
  );
};

export default memo(Search);
