import {
  Badge,
  Checkbox,
  Dropdown,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Tooltip,
} from '@mui/joy';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import actionTypes from '@/redux/actions/actionTypes';

import { ColumnsIcon, ColumnsIconSlash } from '../../icons/Icons';

const propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      allowDeselect: PropTypes.bool,
      visible: PropTypes.bool,
    }),
  ).isRequired,
  onSelectFilter: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

const MultiSelectColumns = ({ columns, onSelectFilter, type }) => {
  const { t } = useTranslation();

  const activeFilterCount = useMemo(
    () =>
      columns.filter((column) => column.visible !== false && column.allowDeselect !== false).length,
    [columns],
  );

  return (
    <Dropdown>
      <div className="align-self-end">
        <Badge badgeContent={activeFilterCount} badgeInset={0}>
          <MenuButton
            slots={{ root: IconButton }}
            slotProps={{ root: { color: 'primary', variant: 'outlined' } }}
          >
            <Tooltip title={t('app.filters.columns')}>
              <div>{activeFilterCount ? <ColumnsIcon /> : <ColumnsIconSlash />}</div>
            </Tooltip>
          </MenuButton>
        </Badge>
      </div>
      <Menu placement="bottom-end">
        {columns
          .filter((c) => c.allowDeselect !== false)
          .map((column) => (
            <MenuItem key={column.key}>
              <Checkbox
                checked={column.visible !== false}
                type="checkbox"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) =>
                  onSelectFilter({
                    payload: {
                      checked: e.target.checked,
                      columnKey: e.target.name,
                    },
                    type: actionTypes.CHANGE_COLUMN_VISIBILITY,
                  })
                }
                name={column.key}
                label={column.title ? t(column.title) : t(`_${type}.columns.${column.key}`)}
                size="sm"
              />
            </MenuItem>
          ))}
      </Menu>
    </Dropdown>
  );
};

MultiSelectColumns.propTypes = propTypes;

export default MultiSelectColumns;
