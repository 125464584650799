import { IconButton, Tooltip } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import EmailStatus from '@/shared/enums/EmailStatus';
import { useEmailBodyByBodyUrl } from '../../../queries';
import { useMailboxContext } from '../../Mailbox/store';

const propTypes = {
  actionCallback: PropTypes.func.isRequired,
  email: PropTypes.shape({
    bcc: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string, name: PropTypes.string })),
    bodyUrl: PropTypes.string,
    cc: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string, name: PropTypes.string })),
    dateEpoch: PropTypes.number,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        contentType: PropTypes.string,
        fileName: PropTypes.string,
        id: PropTypes.string,
        isAttachment: PropTypes.bool,
      }),
    ),
    from: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string, name: PropTypes.string })),
    id: PropTypes.string,
    status: PropTypes.string,
    subject: PropTypes.string,
    to: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string, name: PropTypes.string })),
  }),
  emailBodyEnabled: PropTypes.bool,
};

const CaseEmailPreviewActions = ({ email, actionCallback, emailBodyEnabled = false }) => {
  const { t } = useTranslation();
  const { updateState } = useMailboxContext();
  const { data: body } = useEmailBodyByBodyUrl(email.bodyUrl, { enabled: emailBodyEnabled });

  const reply = () => {
    updateState({
      isForward: false,
      isReplyAll: false,
      replyEmail: {
        ...email,
        body: body ?? '',
      },
    });
    actionCallback();
  };

  const replyAll = () => {
    updateState({
      isForward: false,
      isReplyAll: true,
      replyEmail: {
        ...email,
        body: body ?? '',
      },
    });
    actionCallback();
  };

  const forward = () => {
    updateState({
      isForward: true,
      isReplyAll: false,
      replyEmail: {
        ...email,
        body: body ?? '',
      },
    });
    actionCallback();
  };

  const retry = () => {
    updateState({
      isForward: false,
      isReplyAll: false,
      isRetry: true,
      replyEmail: {
        ...email,
        body: body ?? '',
      },
    });
    actionCallback();
  };

  return (
    <>
      <Tooltip title={t('common:reply')}>
        <IconButton size="sm" variant="plain" onClick={reply}>
          <FontAwesomeIcon icon={['fas', 'reply']} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('common:replyAll')}>
        <IconButton size="sm" variant="plain" onClick={replyAll}>
          <FontAwesomeIcon icon={['fas', 'reply-all']} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('common:forward')}>
        <IconButton size="sm" variant="plain" onClick={forward}>
          <FontAwesomeIcon icon={['fas', 'share']} />
        </IconButton>
      </Tooltip>
      {email.status === EmailStatus.Failed && (
        <Tooltip title={t('common:retry')}>
          <IconButton size="sm" variant="plain" onClick={retry}>
            <FontAwesomeIcon icon={['fas', 'rotate']} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

CaseEmailPreviewActions.propTypes = propTypes;

export default CaseEmailPreviewActions;
