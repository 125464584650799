import React from 'react';

type Props = {
  className?: string;
  uri: string;
  onLoad?: () => void;
  onError?: () => void;
};

const MSDocRenderer: React.FC<Props> = ({ uri, onLoad, onError, className }) => (
  <div className={className}>
    <iframe
      className="h-[80vh] w-full border-none"
      id="msdoc-iframe"
      title="msdoc-iframe"
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(uri)}`}
      onLoad={onLoad}
      onError={onError}
    />
  </div>
);

export default MSDocRenderer;
