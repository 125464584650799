import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import BicInput from '../components/inputs/BicInput';

const FormikBicInput = ({ name = 'bic', ...rest }) => {
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const getObjName = () => name.split('.')[0];
  const getValue = () => {
    if (!name.includes('[')) {
      return values[name] ?? values[getObjName()]?.bic;
    }
    const parts = name.split('.');
    const arrayName = parts[0].split('[')[0];
    const index = parts[0].split('[')[1].substring(0, 1);
    const property = parts[1];
    return values[arrayName][index][property];
  };

  return (
    <BicInput
      callback={({ bic }) => {
        setFieldValue(name, bic);
        setFieldTouched(name, true);
      }}
      errorText={errors[name]}
      touched={touched[name] ?? touched[getObjName()]?.bic}
      defaultValue={getValue()}
      name={name}
      {...rest}
    />
  );
};

FormikBicInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikBicInput;
