import { useMutation, useQueryClient } from '@tanstack/react-query';

import { caseApi } from '@/shared/api';
import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useUpdateAxaServiceReference = () => {
  const queryClient = useQueryClient();
  const { sendDefaultError } = useNotification();

  const { mutate, isLoading } = useMutation(caseApi.updateAxaServiceReference, {
    onError: (err, data, previousData) => {
      const queryKey = [queryIds.cases.GET_CASE, data.caseId];
      queryClient.setQueryData(queryKey, previousData);

      sendDefaultError(err);
    },
    onMutate: async ({ caseId, axaServiceReference }) => {
      const queryKey = [queryIds.cases.GET_CASE, caseId];
      await queryClient.cancelQueries(queryKey);
      const previousData = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (old: any) => ({
        ...old,
        axaServiceReference,
      }));

      return previousData;
    },
  });

  return {
    isUpdatingAxaServiceReference: isLoading,
    updateAxaServiceReference: mutate,
  };
};

export default useUpdateAxaServiceReference;
