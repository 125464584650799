import {
  Autocomplete,
  AutocompleteOption,
  FormControl,
  FormHelperText,
  FormLabel,
  ListItemContent,
} from '@mui/joy';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { trpc } from '@/config/trpc';

const propTypes = {
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

const FormikCalendarSelector = ({ name, label, required = false, multiple = false }) => {
  const { t } = useTranslation();
  const { values, touched, errors, setFieldValue } = useFormikContext();

  const { data: linkedCalendars, isLoading: linkedCalendarsAreLoading } =
    trpc.calendar.getLinked.useQuery();

  const calendarOptions = useMemo(() => {
    if (!linkedCalendars || linkedCalendarsAreLoading) return [];

    return linkedCalendars.map((c) => ({
      emailAddress: c.emailAddress,
      label: c.calendarName,
      value: c.id,
    }));
  }, [linkedCalendars, linkedCalendarsAreLoading]);

  return (
    <FormControl>
      <FormLabel required={required}>{label}</FormLabel>
      <Autocomplete
        onChange={(e, target) => setFieldValue(name, target?.value || null)}
        size="sm"
        value={calendarOptions.find((c) => c.value === values[name]) ?? null}
        loading={linkedCalendarsAreLoading}
        placeholder={t('select.label')}
        options={calendarOptions}
        renderOption={(props, option) => (
          <AutocompleteOption {...props}>
            <ListItemContent className="pl-2">{option.label}</ListItemContent>
          </AutocompleteOption>
        )}
        multiple={multiple}
      />
      {touched[name] && errors[name] && (
        <FormHelperText className="text-xs">{errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
};

FormikCalendarSelector.propTypes = propTypes;

export default FormikCalendarSelector;
