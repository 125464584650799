import { useQueryClient } from '@tanstack/react-query';

import { trpc } from '@/config/trpc';
import { queryIds } from '@/shared/utils/constants';

const useCreateContact = () => {
  const queryClient = useQueryClient();
  const utils = trpc.useUtils();

  return trpc.contact.create.useMutation({
    onSuccess: () => {
      utils.contact.list.invalidate();
      queryClient.invalidateQueries([queryIds.contacts.GET_CONTACTS]);
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === queryIds.crm.GET_OPTIONS,
      });
    },
  });
};

export default useCreateContact;
