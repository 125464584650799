import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import fileApi from '@/shared/api/file/file';
import useNotification from '@/shared/hooks/UseNotification';

const useUpdateFilesType = () => {
  const { t } = useTranslation();
  const { sendDefaultError, sendNotification } = useNotification();

  const { mutate, isLoading } = useMutation({
    mutationFn: fileApi.putUpdateFilesType,
    onError: (err) => {
      sendDefaultError(err);
    },
    onSuccess: () => {
      sendNotification({
        header: t('success'),
        message: t('typeSuccessfullyUpdated', { type: t('files') }),
        variant: 'success',
      });
    },
  });

  return {
    isUpdatingFilesType: isLoading,
    updateFilesType: mutate,
  };
};

export default useUpdateFilesType;
