import { useContext, useEffect, useMemo } from 'react';

import { SelectionOptions, SharedOptions, UseAutocompleteOptionsResult } from './types';
import { BookYearOption } from '@/types/trpc/hoa';
import { BookyearStatus } from '~/common/enums';

import { AppContext } from '@/shared/context/context';
import { trpc } from '@/config/trpc';
import useAutocompleteOptions from './useAutocompleteOptions';

type BookYearOptionExtension = Pick<BookYearOption, 'data'>;

type Options = SharedOptions & {
  activeOnly?: boolean;
  homeownerAssociationId: string | null | undefined;
  useGlobalAsDefault?: boolean;
} & SelectionOptions<string, BookYearOptionExtension>;

function useBookYearAutocomplete(
  options: Options & { multiple: true },
): UseAutocompleteOptionsResult<string, true, BookYearOptionExtension>;

function useBookYearAutocomplete(
  options: Options & { multiple?: false },
): UseAutocompleteOptionsResult<string, false | undefined, BookYearOptionExtension>;

function useBookYearAutocomplete({
  homeownerAssociationId,
  activeOnly = false,
  useGlobalAsDefault = false,
  ...useAutocompleteOptionProps
}: Options): UseAutocompleteOptionsResult<
  string,
  typeof useAutocompleteOptionProps.multiple,
  BookYearOptionExtension
> {
  const { data: options, isLoading } = trpc.hoa.bookYear.autocomplete.useQuery(
    {
      bookYearStatus: activeOnly ? [BookyearStatus.Open] : undefined,
      homeownerAssociationId: homeownerAssociationId as string,
    },
    { enabled: !!homeownerAssociationId },
  );
  const { ui } = useContext(AppContext);

  const autocompleteProps = useAutocompleteOptions({
    data: options,
    isLoading,
    ...useAutocompleteOptionProps,
  });

  const fallbackOption = useMemo(() => {
    if (!options) return null;

    let matchingOption;

    if (useGlobalAsDefault && ui.currentBookYearId) {
      matchingOption = options.find((option) => option.value === ui.currentBookYearId);
    } else {
      const currentYear = new Date().getFullYear();

      matchingOption = options.find((option) => option.data.year === currentYear);
    }

    return matchingOption || null;
  }, [options, ui.currentBookYearId, useGlobalAsDefault]);

  useEffect(() => {
    const hasValue = useAutocompleteOptionProps.multiple
      ? !!(autocompleteProps.value as BookYearOption[]).length
      : !!autocompleteProps.value;

    if (!fallbackOption || hasValue) return;

    if (useAutocompleteOptionProps.multiple) {
      useAutocompleteOptionProps.callback([fallbackOption]);
    } else {
      useAutocompleteOptionProps.callback(fallbackOption);
    }
  }, [
    autocompleteProps.multiple,
    autocompleteProps.value,
    fallbackOption,
    useAutocompleteOptionProps,
  ]);

  return {
    ...autocompleteProps,
    disabled: !homeownerAssociationId || autocompleteProps.disabled,
  };
}

export default useBookYearAutocomplete;
