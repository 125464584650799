import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Skeleton } from '@mui/material';
import { Editor as TinyMCEditor } from 'tinymce';
import { useTranslation } from 'react-i18next';

import { LanguageCode } from '~/common/enums';
import { Nullable } from '~/common/types';
import { RichTextEditorProps } from './types';

import { tinyMCEToolbarSections } from '@/shared/utils/constants';

const BASE_PLUGINS = [
  'advlist',
  'autolink',
  'lists',
  'link',
  'image',
  'charmap',
  'preview',
  'anchor',
  'searchreplace',
  'visualblocks',
  'code',
  'fullscreen',
  'insertdatetime',
  'media',
  'table',
  'powerpaste',
  'tinymcespellchecker',
];

const CONTENT_LANGUAGES = [
  { code: 'en_US', title: 'English (US)' },
  { code: 'en_UK', title: 'English (UK)' },
  { code: 'fr', title: 'Français' },
  { code: 'nl', title: 'Nederlands' },
];

const { CLEAR, TABLE_PROPS, TEXT_DECORATION, UNDO_REDO, UNDO_REDO_CLEAR } = tinyMCEToolbarSections;

const DEFAULT_TOOLBAR_ITEMS = [UNDO_REDO, TEXT_DECORATION];
const DEFAULT_TOOLBAR_ITEMS_CLEARABLE = [UNDO_REDO_CLEAR, TEXT_DECORATION];

const SPELLCHECKER_LANGUAGE_MAP = {
  [LanguageCode.EN]: 'en_UK',
  [LanguageCode.FR]: 'fr',
  [LanguageCode.NL]: 'nl',
};

const SPELLCHECKER_LANGUAGE_OPTIONS =
  'English (US)=en_US, English (UK)=en_UK, Français=fr, Nederlands=nl';

const getCurrentSpellCheckerLanguage = (languageCode: string) =>
  Object.hasOwn(SPELLCHECKER_LANGUAGE_MAP, languageCode)
    ? SPELLCHECKER_LANGUAGE_MAP[languageCode as keyof typeof SPELLCHECKER_LANGUAGE_MAP]
    : SPELLCHECKER_LANGUAGE_MAP[LanguageCode.EN];

const RichTextEditor = forwardRef<TinyMCEditor, RichTextEditorProps>(
  (
    {
      callback,
      clearable = false,
      disabled = false,
      disableSpellChecker = false,
      enableTags = false,
      height = 500,
      onBlur,
      removeLineBreaks = false,
      showMenubar = false,
      setup,
      tags = [],
      toolbarItems,
      value = '',
      ...rest
    },
    forwardedRef,
  ) => {
    const [inited, setInited] = useState(false);

    const editorRef = useRef<Nullable<TinyMCEditor>>(null);

    const { i18n, t } = useTranslation();

    const toolbarSections = (() => {
      if (toolbarItems) return toolbarItems;

      return clearable ? DEFAULT_TOOLBAR_ITEMS_CLEARABLE : DEFAULT_TOOLBAR_ITEMS;
    })();

    const checkForInvalidTags = useCallback(() => {
      if (!enableTags || !editorRef.current) return;

      const body = editorRef.current.getBody();
      const tagsInBody = body.getElementsByClassName('mce-mergetag');

      if (!tagsInBody.length) return;

      for (let i = 0; i < tagsInBody.length; i += 1) {
        const nodes = tagsInBody[i].childNodes;

        for (let j = 0; j < nodes.length; j += 1) {
          if (nodes[j].nodeType === 3) {
            const tag = nodes[j].nodeValue;

            if (!tags.some((tMenuItem) => tMenuItem.menu.some((tItem) => tItem.value === tag))) {
              tagsInBody[i].classList.add('invalid');
            } else {
              tagsInBody[i].classList.remove('invalid');
            }
          }
        }
      }
    }, [enableTags, tags]);

    const clearEditor = () => {
      if (!editorRef.current) return;

      editorRef.current.setContent('');
    };

    const getPlugins = () => {
      const plugins = BASE_PLUGINS;

      if (enableTags && tags.length) {
        plugins.push('mergetags');
      }

      return plugins;
    };

    const getToolbar = () => {
      if (disabled) return '';

      let joinedSections = toolbarSections.join(' | ');

      if (clearable && !joinedSections.includes(CLEAR))
        joinedSections = [joinedSections, CLEAR].join(' | ');

      return joinedSections;
    };

    const handleInit = (editor: TinyMCEditor) => {
      setInited(true);

      editorRef.current = editor;

      checkForInvalidTags();
    };

    useImperativeHandle(forwardedRef, () => editorRef.current as TinyMCEditor);

    useEffect(() => checkForInvalidTags(), [checkForInvalidTags]);

    return (
      <div className="relative h-full">
        {!inited && (
          <Skeleton
            height={height}
            width="100%"
            variant="rounded"
            style={{ position: 'absolute', zIndex: 5 }}
          />
        )}
        <Editor
          onBlur={onBlur}
          value={value}
          plugins={getPlugins()}
          init={{
            branding: false,
            browser_spellcheck: false,
            content_css: ['material-outline', '/styles/tiny.css'],
            content_langs: CONTENT_LANGUAGES,
            element_format: 'xhtml',
            height,
            icons: 'material',
            language: i18n.language,
            menubar: showMenubar,
            mergetags_list: tags,
            mergetags_prefix: '{{',
            mergetags_suffix: '}}',
            remove_linebreaks: removeLineBreaks,
            setup: (editor) => {
              if (clearable) {
                editor.ui.registry.addButton('clear', {
                  icon: 'remove',
                  onAction: clearEditor,
                  tooltip: t('clear'),
                });
              }

              setup?.(editor);
            },
            skin: 'material-outline',
            spellchecker_active: !disableSpellChecker,
            spellchecker_language: getCurrentSpellCheckerLanguage(i18n.language),
            spellchecker_languages: SPELLCHECKER_LANGUAGE_OPTIONS,
            table_toolbar: TABLE_PROPS,
            toolbar1: getToolbar(),
            ...rest,
          }}
          onEditorChange={callback}
          onInit={(_, editor) => handleInit(editor)}
          disabled={disabled}
        />
      </div>
    );
  },
);

export default RichTextEditor;
