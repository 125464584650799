import { Badge, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatUtcDate, isInTheFuture } from '@/shared/utils/helpers';
import { AppContext } from '@/shared/context/context';
import { CaseContext } from '../CaseContext';
import EntityType from '@/shared/enums/EntityType';
import signalRMessages from '@/redux/actions/signalRMessages';
import { trpc } from '@/config/trpc';

import CaseAppointments from './BodySections/AppointmentsSidebar/CaseAppointments';
import CaseNotes from './BodySections/CaseNotes';
import CaseRepairRemarks from './BodySections/CaseRepairRemarks';
import CaseTasks from './BodySections/TasksSidebar/CaseTasks';
import { CircleExclamation } from '@/shared/icons/Icons';

const CaseSidebar = () => {
  const { caseEntities, currentCase, canEditCase } = useContext(CaseContext);
  const { currentTeam, currentTeamUser, socket } = useContext(AppContext);
  const { t } = useTranslation();

  const [hasRemarks, setHasRemarks] = useState(false);

  const {
    data: caseTasks,
    isLoading: caseTasksIsLoading,
    refetch,
  } = trpc.task.caseTasks.useQuery({ caseId: currentCase.id });

  const assignedTasks = useMemo(() => {
    if (caseTasks && caseTasks.length && currentTeam && currentTeamUser) {
      return caseTasks.filter(
        (task) =>
          task.taskUsers.some(
            (tu) =>
              tu.isAssigned && tu.teamUserId === currentTeamUser.id && tu.teamId === currentTeam.id,
          ) && !task.completedDate,
      );
    }
    return [];
  }, [caseTasks, currentTeam, currentTeamUser]);

  const upcomingAppointments = useMemo(() => {
    if (caseEntities && caseEntities.length) {
      return caseEntities
        .filter((ent) => ent.type === EntityType.Appointment)
        .filter((a) => isInTheFuture(formatUtcDate(a.startTime, 'YYYY-MM-DDTHH-mm-ss')));
    }
    return [];
  }, [caseEntities]);

  useEffect(() => {
    socket?.on(signalRMessages.TaskCreated, () => refetch());
    socket?.on(signalRMessages.TaskUpdated, () => refetch());
    socket?.on(signalRMessages.TaskFinished, () => refetch());
    socket?.on(signalRMessages.TaskSnoozed, () => refetch());

    return () => {
      socket?.off(signalRMessages.TaskCreated);
      socket?.off(signalRMessages.TaskUpdated);
      socket?.off(signalRMessages.TaskFinished);
      socket?.off(signalRMessages.TaskSnoozed);
    };
  }, [refetch, socket]);

  return (
    <Tabs defaultValue={1} className="min-h-full">
      <TabList className="sticky top-0 flex space-x-1 overflow-auto bg-white p-2 text-xs">
        <Tab>{t('notes')}</Tab>
        <Tab>
          <Badge badgeContent={assignedTasks.length || null} size="sm" max={99} badgeInset="-15%">
            {t('tasks')}
          </Badge>
        </Tab>
        <Tab>
          <Badge
            badgeContent={upcomingAppointments.length || null}
            size="sm"
            max={99}
            badgeInset="-15%"
          >
            {t('appointments')}
          </Badge>
        </Tab>
        {canEditCase &&
          currentCase.relations.some((rel) => rel.teamId === currentTeam.id && rel.isOwner) &&
          currentTeam.allowRepairManagement &&
          currentCase.isRepairCase && (
            <Tab>
              <Badge
                badgeContent={hasRemarks ? <CircleExclamation className="text-primary" /> : null}
                color="plain"
                size="sm"
                max={99}
                badgeInset="-15%"
              >
                {t('repair')}
              </Badge>
            </Tab>
          )}
      </TabList>
      <TabPanel value={0} className="p-0">
        <CaseNotes />
      </TabPanel>
      <TabPanel value={1}>
        {!caseTasksIsLoading && !!caseTasks && <CaseTasks caseTasks={caseTasks} />}
      </TabPanel>
      <TabPanel value={2}>
        <CaseAppointments
          currentCase={currentCase}
          caseEntities={caseEntities}
          canEdit={canEditCase}
        />
      </TabPanel>
      <TabPanel value={3}>
        <CaseRepairRemarks currentCase={currentCase} setHasRemarks={setHasRemarks} />
      </TabPanel>
    </Tabs>
  );
};

export default CaseSidebar;
