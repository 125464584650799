import { Field, FieldProps, useFormikContext } from 'formik';

import SelectCRMJoy, { SelectCRMJoyProps } from '../select/crm/SelectCRMJoy';
import { CrmAutoCompleteOption } from '~/common/types';

type Props = Omit<SelectCRMJoyProps, 'value' | 'onChange' | 'error'> & {
  name: string;
  disabled?: boolean;
  callback?: (value: CrmAutoCompleteOption | CrmAutoCompleteOption[] | null) => void;
};

const FormikCrmSelectorJoy: React.FC<Props> = ({ name, multiple = false, callback, ...rest }) => {
  const { errors, values, touched, setFieldValue } = useFormikContext<{
    [name: string]: CrmAutoCompleteOption | CrmAutoCompleteOption[] | null;
  }>();

  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <SelectCRMJoy
          name={name}
          error={touched[name] && errors[name] ? errors[name] : ''}
          onBlur={field.onBlur}
          multiple={multiple}
          value={
            multiple
              ? (values[name] as CrmAutoCompleteOption[])
              : (values[name] as CrmAutoCompleteOption | null)
          }
          onChange={(option: CrmAutoCompleteOption[] | CrmAutoCompleteOption | null) => {
            setFieldValue(name, option);
            if (callback) {
              callback(option);
            }
          }}
          {...rest}
        />
      )}
    </Field>
  );
};

export default FormikCrmSelectorJoy;
