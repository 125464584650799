import { AvatarGroup } from '@mui/material';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { makeId } from '../../utils/helpers';

import AvatarWithColor from '../2.0/AvatarWithColor';
import KpcTooltip from '../Tooltips/KpcTooltip';

const AdminAvatars = styled(AvatarGroup)`
  div:first-child {
    height: ${(props) => {
      if (props.small === 'true') return '20px';
      if (props.medium === 'true') return '30px';
      return '30px';
    }};
    width: ${(props) => {
      if (props.small === 'true') return '20px';
      if (props.medium === 'true') return '30px';
      return '30px';
    }};
    font-size: ${(props) => {
      if (props.small === 'true') return '13px';
      if (props.medium === 'true') return '15px';
      return '15px';
    }};
  }
`;

const KpcAvatarGroup = ({ names, small, medium }) => {
  if (!names || names.length === 0) {
    return '-';
  }
  return (
    <KpcTooltip disableFocusListener title={names.join(', ')}>
      <AdminAvatars small={small.toString()} medium={medium.toString()} max={5}>
        {names.map((c) => (
          <AvatarWithColor key={makeId(5)} size={small ? 'sm' : 'md'} name={c} />
        ))}
      </AdminAvatars>
    </KpcTooltip>
  );
};

KpcAvatarGroup.propTypes = {
  medium: PropTypes.bool,
  names: PropTypes.arrayOf(PropTypes.string),
  small: PropTypes.bool,
};

KpcAvatarGroup.defaultProps = {
  medium: false,
  names: [],
  small: false,
};

export default KpcAvatarGroup;
