import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { useCallback, useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import API from '@/shared/api/ApiService';
import { AppContext } from '@/shared/context/context';
import { objectToFormData } from '@/shared/utils/helpers';
import Priority from '@/shared/enums/Priority';
import TogglePropTypes from '@/shared/prop-types/TogglePropTypes';
import useCreateInsuranceClaim from '@/mutations/insuranceClaim/useCreateInsuranceClaim';
import useNotification from '@/shared/hooks/UseNotification';

import AddInsuranceCaseModalFooter from './AddInsuranceCaseModalFooter';
import BuildingSelectorStep from './Steps/BuildingSelectorStep/BuildingSelectorStep';
import CaseDataStep from './Steps/CaseDataStep/CaseDataStep';
import CaseInfoStep from './Steps/CaseInfoStep/CaseInfoStep';
import CaseManagementStep from './Steps/CaseManagementStep/CaseManagementStep';
import DamageDetailsStep from './Steps/DamageDetailsStep/DamageDetailsStep';
import DocumentUploaderStep from './Steps/DocumentUploaderStep/DocumentUploaderStep';
import FinishedSteps from './FinishedSteps';
import FormikStepper from './Steps/FormikStepper';
import InvolvedPartiesStep from './Steps/InvolvedPartiesStep/InvolvedPartiesStep';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';

const propTypes = {
  toggle: TogglePropTypes,
};

const AddInsuranceCaseModal = ({ toggle }) => {
  const { currentTeam, currentTeamUser } = useContext(AppContext);
  const { t } = useTranslation('common');

  const labels = [
    t('common:riskObject'),
    t('common:policy'),
    t('common:caseInfo'),
    t('common:damageDetails'),
    t('common:involvedParties'),
    t('common:documentation'),
    t('common:management'),
  ];
  const [data, setData] = useState({ step: 0 });
  const { createCaseAskForClient } = currentTeam;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { sendNotification } = useNotification();
  const navigate = useNavigate();

  const { createInsuranceClaimAsync } = useCreateInsuranceClaim();

  const popperContainerRef = useRef();
  const popperContainerInvolvedPartyRef = useRef();

  const isLastStep = data.step === labels.length - 1 || data.step > labels.length;

  const onSuggestedPoliciesFound = useCallback((suggestedPolicies) => {
    setData((prev) => ({ ...prev, suggestedPolicies }));
  }, []);

  const onAddingInvolvedParty = useCallback((isAddingInvolvedParty) => {
    setData((prev) => ({ ...prev, isAddingInvolvedParty }));
  }, []);

  const handleOnSubmit = async (newInsuranceCase) => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const { policySelector, caseRelations, sendTo, files, ...rest } = newInsuranceCase;

    const policies = policySelector
      ? policySelector.policies.map((p) =>
          p.isPolicyNumber
            ? {
                policyId: null,
                policyNumber: p.policyNumber,
              }
            : {
                policyId: p.id,
                policyNumber: p.policyNumber,
              },
        )
      : null;

    const relations = caseRelations.map((cr) => ({
      ...cr,
      address: {
        addressLine: cr.address.relationAddressAddressLine,
        city: cr.address.relationAddressCity,
        country: cr.address.relationAddressCountry,
        postalCode: cr.address.relationAddressPostalCode,
      },
      companyName: cr.name,
    }));

    const requestData = {
      policies,
      relations,
      sendTo: sendTo?.team?.id,
      ...rest,
    };

    await createInsuranceClaimAsync(requestData, {
      onError: () => {
        setIsSubmitting(false);
      },
      onSuccess: async (insuranceClaimId) => {
        if (files != null && files.length > 0) {
          const fileData = {
            files,
            insuranceClaimId,
          };
          const fileFormData = objectToFormData(fileData);
          await API.postAddFilesToNewInsuranceClaim(fileFormData);
        }

        if (newInsuranceCase.openUponSubmit) {
          navigate(`/case/${insuranceClaimId}`);
        }

        toggle.hide();

        if (requestData.selfManagement && !requestData.repairByKeypoint) {
          sendNotification({
            header: t('common:success'),
            message: t('common:typeSuccessfullyAdded', { type: t('common:insuranceCase') }),
            variant: 'success',
          });
          return;
        }

        if (requestData.selfManagement && requestData.repairByKeypoint) {
          sendNotification({
            header: t('common:success'),
            message: t('common:yourCaseHasBeenSuccessfullySentToKeypoint'),
            onSuccessRoute: `/case/${insuranceClaimId}`,
            variant: 'success',
          });
          return;
        }

        sendNotification({
          header: t('common:success'),
          message: t('common:yourCaseHasBeenSuccessfullySent'),
          onSuccessRoute: `/case/${insuranceClaimId}`,
          variant: 'success',
        });
      },
    });
  };

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <div style={{ marginTop: '50px' }}>
            <BuildingSelectorStep popperContainer={popperContainerRef} />
          </div>
        );
      case 1:
        return (
          <div style={{ marginTop: '50px' }}>
            <FinishedSteps data={data} />
            <CaseDataStep onSuggestedPoliciesFound={onSuggestedPoliciesFound} />
          </div>
        );
      case 2:
        return (
          <div>
            <div style={{ marginTop: '50px' }}>
              <FinishedSteps data={data} />
              <CaseInfoStep />
            </div>
          </div>
        );
      case 3:
        return (
          <div style={{ marginTop: '50px' }}>
            <FinishedSteps data={data} />
            <DamageDetailsStep />
          </div>
        );
      case 4:
        return (
          <div>
            <FinishedSteps data={data} />
            <InvolvedPartiesStep
              popperContainer={popperContainerInvolvedPartyRef}
              onAddingInvolvedParty={onAddingInvolvedParty}
              isAddingInvolvedParty={data.isAddingInvolvedParty ?? false}
            />
          </div>
        );
      case 5:
        return (
          <div style={{ marginTop: '50px' }}>
            <FinishedSteps data={data} />
            <DocumentUploaderStep />
          </div>
        );
      case 6:
        return (
          <div style={{ marginTop: '50px' }}>
            <FinishedSteps data={data} />
            <CaseManagementStep />
          </div>
        );
      default:
        throw new Error('Unknown step');
    }
  };

  const classes = classNames({
    'overflow-x-visible': data.step === 0,
    'overflow-y-visible': data.step === 0,
    'w-2/3': true,
  });

  return (
    <KpcModal size={classes} title={t('common:createCase')} toggle={toggle}>
      <>
        <Formik
          validateOnMount
          onSubmit={handleOnSubmit}
          initialValues={{
            admins: currentTeamUser ? [currentTeamUser.id] : [],
            automaticReference: true,
            buildingSelector: null,
            caseRelations: null,
            classificationId: null,
            damageToCommonUnits: false,
            damageToPrivateUnits: false,
            damageType: '',
            description: null,
            files: [],
            incidentDate: null,
            policySelector: null,
            priority: Priority.Normal,
            reference: '',
            repairByKeypoint: false,
            rikRequested: false,
            selfManagement: null,
            sendTo: null,
            thirdPartyInvolved: false,
            verbalProcess: false,
          }}
          validationSchema={yup.object().shape({
            admins: yup
              .array()
              .nullable()
              .min(1, t('errors:fieldIsRequired'))
              .required(t('errors:fieldIsRequired')),
            automaticReference: yup.bool(),
            // buildingSelector: yup.object().nullable().required(t('errors:fieldIsRequired')), temporarily disabled
            caseRelations: yup
              .array()
              .nullable()
              .test('caseRelationValidation', t('errors:fieldIsRequired'), (value) => {
                if (!value) {
                  return false;
                }
                if (createCaseAskForClient && !value.some((rel) => rel.isClient)) {
                  return false;
                }
                return value.some((rel) => rel.isRequestor);
              }),
            damageToCommonUnits: yup.bool(),
            damageToPrivateUnits: yup.bool(),
            damageType: yup.string().nullable().required(t('errors:fieldIsRequired')),
            description: yup.string().nullable().required(t('errors:fieldIsRequired')),
            incidentDate: yup.string().nullable().required(t('errors:fieldIsRequired')),
            priority: yup.string().nullable().required(t('errors:fieldIsRequired')),
            reference: yup.string().when('automaticReference', {
              is: false,
              then: yup.string().nullable().required(t('errors:fieldIsRequired')),
            }),
            sendTo: yup
              .object()
              .nullable()
              .when('selfManagement', {
                is: false,
                then: yup
                  .object()
                  .nullable()
                  .test('sendToValidation', t('errors:fieldIsRequired'), (value) => value?.team),
              }),
            thirdPartyInvolved: yup.bool(),
            verbalProcess: yup.bool(),
          })}
        >
          <>
            <Form>
              <FormikStepper labels={labels} data={data} setData={setData} />
              {handleSteps(data.step)}
              <div>
                <AddInsuranceCaseModalFooter
                  data={data}
                  setData={setData}
                  isLastStep={isLastStep}
                  isSubmitting={isSubmitting}
                />
              </div>
            </Form>
            <div ref={popperContainerRef} />
          </>
        </Formik>
        <div ref={popperContainerInvolvedPartyRef} />
      </>
    </KpcModal>
  );
};

AddInsuranceCaseModal.propTypes = propTypes;

export default AddInsuranceCaseModal;
