import { Button } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CancelIcon, LoadingIcon, SaveIcon } from '@/shared/icons/Icons';

type ModalFooterProps = {
  onCancel: () => void;
  onSubmit?: () => void;
  cancelLabel?: string;
  submitLabel?: string;
  isSubmitting?: boolean;
  disableSubmit?: boolean;
};

const ModalFooter: React.FC<ModalFooterProps> = ({
  onCancel,
  onSubmit,
  cancelLabel,
  submitLabel,
  isSubmitting,
  disableSubmit,
}) => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <div className="mt-4 flex justify-end space-x-4">
      <Button startDecorator={<CancelIcon />} color="neutral" variant="outlined" onClick={onCancel}>
        {cancelLabel || t('cancel')}
      </Button>
      <Button
        disabled={disableSubmit}
        startDecorator={isSubmitting ? <LoadingIcon /> : <SaveIcon />}
        type="submit"
        onClick={handleSubmit}
      >
        {submitLabel || t('save')}
      </Button>
    </div>
  );
};

export default ModalFooter;
