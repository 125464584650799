import { DialogTitle, Divider, Modal, ModalClose, ModalDialog } from '@mui/joy';
import classNames from 'classnames';

import { UseToggle } from '@/shared/hooks/UseToggle';

type Props<T> = {
  backdropBehavior?: 'closeOnSingleClick' | 'closeOnDoubleClick' | 'noCloseOnClick';
  children: React.ReactNode;
  centered?: boolean;
  className?: string;
  description?: string;
  hideClose?: boolean;
  icon?: React.ReactNode;
  noDivider?: boolean;
  onClose?: () => void;
  overflow?: string;
  size?: string;
  title?: string;
  toggle: UseToggle<T>;
  [key: string]: unknown;
};

const KpcModal = <T,>({
  backdropBehavior = 'closeOnDoubleClick',
  centered = false,
  children,
  className,
  description,
  hideClose = false,
  icon,
  noDivider = false,
  size = 'w-1/2',
  title,
  toggle,
  onClose,
  ...rest
}: Props<T>) => {
  const handleClose = () => {
    onClose?.();
    toggle.hide();
  };

  const onClickBackdrop = () => {
    if (backdropBehavior === 'closeOnSingleClick') {
      handleClose();
    }
  };

  const onDoubleClickBackdrop = () => {
    if (backdropBehavior === 'closeOnDoubleClick') {
      handleClose();
    }
  };

  const renderHeading = !!title || !!icon || !!description || !noDivider;

  return (
    <Modal
      open={toggle.value}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') return;

        handleClose();
      }}
      slotProps={{
        backdrop: {
          onClick: onClickBackdrop,
          onDoubleClick: onDoubleClickBackdrop,
        },
      }}
      {...rest}
    >
      <ModalDialog
        className={classNames(
          size,
          className,
          'overflow-y-auto overflow-x-hidden [scrollbar-gutter:stable_both-edges]',
        )}
      >
        {!hideClose && <ModalClose />}
        {renderHeading && (
          <div className={classNames('flex flex-col', { 'items-center justify-center': centered })}>
            {(!!title || !!icon) && (
              <DialogTitle className="flex items-center gap-2 pb-2 text-base">
                {icon} {title}
              </DialogTitle>
            )}
            {!noDivider && <Divider className="bg-gray-400" />}
            {!!description && <p className="py-2 text-sm">{description}</p>}
          </div>
        )}
        {children}
      </ModalDialog>
    </Modal>
  );
};

export default KpcModal;
