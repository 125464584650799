import {
  AddProcurationRequest,
  ApproveProcurationRequest,
  RemoveProcurationRequest,
  UpdateProcurationRequest,
} from '~/common/types/meeting/meetingProcurationRequests';
import {
  CreateAgendaPointRequest,
  CreateMeetingRequest,
  DeleteAgendaPointRequest,
  RegisterAttendeesRequest,
  UpdateAgendaPointRequest,
  UpdateMeetingRequest,
  UpdateMeetingStatusRequest,
} from '~/common/types/meeting/meetingRequests';
import {
  CreateMeetingTaskRequest,
  MeetingTask,
  UpdateMeetingTaskRequest,
} from '~/common/types/meeting/meetingTasks';
import {
  DownloadInvitationAttachmentRequest,
  InvitationPreviewRequest,
  InvitationPreviewResponse,
  InvitationsPreviewRequest,
  InvitationsPreviewResponse,
  MeetingAttendee,
  SendCommunicationsRequest,
  SendInvitationsRequest,
} from '~/common/types/meeting/meetingCommunications';
import { ExtendedMeeting, Meeting } from '~/common/types/meeting/meeting';
import {
  InviteAttendeeRequest,
  UpdateAttendeeInvitationStatusRequest,
  UpdateAttendeeRemarkRequest,
} from '~/common/types/meeting/meetingAttendeeRequest';
import { MeetingProcurationHolderResponse } from '~/common/types/meeting/meetingProcurations';

import config from '@/config/config';
import createRestClient from '../restClient';
import { objectToFormData } from '@/shared/utils/helpers';

const client = createRestClient(config.baseURL);
const rawClient = createRestClient(config.baseURL, true);

const getMeeting = (meetingId: string): Promise<ExtendedMeeting> =>
  client.get(`/meeting/${meetingId}`);

const getMeetingsForHomeownerAssociation = (homeownerAssociationId: string): Promise<Meeting[]> =>
  client.get(`/meeting/hoa/${homeownerAssociationId}`);

const deleteMeeting = (id: string) => client.delete(`/meeting/${id}`);

const createMeeting = (data: CreateMeetingRequest): Promise<Meeting> =>
  client.post('/meeting', data);

const updateMeeting = (data: UpdateMeetingRequest) => client.put('/meeting', data);

const updateMeetingStatus = (data: UpdateMeetingStatusRequest) =>
  client.put('/meeting/status', data);

const deleteAgendaPoint = (data: DeleteAgendaPointRequest) =>
  client.delete(`/meeting/agenda-point/${data.id}`, { data });

const restClient = createRestClient();

const createAgendaPoint = (data: CreateAgendaPointRequest) =>
  restClient.post('meeting/agenda-point', objectToFormData(data));

const updateAgendaPoint = (data: UpdateAgendaPointRequest) =>
  restClient.put('meeting/agenda-point', objectToFormData(data));

const getMeetingTasks = (
  meetingId: string,
  homeownerAssociationId: string,
): Promise<MeetingTask[]> =>
  restClient.get(`/meeting/${meetingId}/task`, { params: { homeownerAssociationId } });

const createMeetingTask = (data: CreateMeetingTaskRequest) =>
  restClient.post(`/meeting/${data.meetingId}/task`, data);

const updateMeetingTask = (data: UpdateMeetingTaskRequest) =>
  restClient.put(`/meeting/${data.meetingId}/task`, data);

const registerAttendee = (data: RegisterAttendeesRequest) =>
  client.put(`/meeting/attendee-register`, data);

const getAttendees = (
  meetingId: string,
  homeownerAssociationId: string,
): Promise<MeetingAttendee[]> =>
  client.get(`/meeting/${meetingId}/attendee.list`, { params: { homeownerAssociationId } });

const getProcurationHolders = (
  meetingId: string,
  divisionKeyId: string,
): Promise<MeetingProcurationHolderResponse[]> =>
  client.get(`/meeting/${meetingId}/procuration-holder.list`, { params: { divisionKeyId } });

const getPossibleBuildingRelations = (
  meetingId: string,
  homeownerAssociationId: string,
): Promise<
  {
    id: string;
    contactId: string;
    companyId: string;
    displayName: string;
    buildingId: string;
    relationId: string;
  }[]
> =>
  client.get(`/meeting/${meetingId}/possibleBuildingRelations`, {
    params: { homeownerAssociationId },
  });

const inviteAttendee = (data: InviteAttendeeRequest) => client.post('/meeting/attendee', data);

const updateAttendeeInvitationStatus = (data: UpdateAttendeeInvitationStatusRequest) =>
  client.put('/meeting/attendee/invitation-status', data);

const updateAttendeeRemark = (data: UpdateAttendeeRemarkRequest) =>
  client.put('/meeting/attendee/remark', data);

const getInvitationsPreview = (
  data: InvitationsPreviewRequest,
): Promise<InvitationsPreviewResponse> =>
  client.get(`/meeting/invitations-preview`, { params: { ...data } });

const getSendReportsPreview = (
  data: InvitationsPreviewRequest,
): Promise<InvitationsPreviewResponse> =>
  client.get(`/meeting/send-reports-preview`, { params: { ...data } });

const getInvitationPreview = (data: InvitationPreviewRequest): Promise<InvitationPreviewResponse> =>
  client.get(`/meeting/invitation-preview`, { params: { ...data } });

const sendInvitations = (data: SendInvitationsRequest) =>
  client.postForm(`/meeting/send-invitations`, objectToFormData(data));

const sendReport = (data: SendCommunicationsRequest) =>
  client.postForm(`/meeting/send-report`, objectToFormData(data));

const downloadInvitationAttachment = (data: DownloadInvitationAttachmentRequest) =>
  rawClient.get<string>(`/meeting/invitation-attachment-download`, { params: { ...data } });

const addProcuration = (data: AddProcurationRequest) =>
  client.post('/meeting/procuration', objectToFormData(data), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

const approveProcuration = (data: ApproveProcurationRequest) =>
  client.put('/meeting/procuration/approve', data);

const updateProcuration = (data: UpdateProcurationRequest) =>
  client.put('/meeting/procuration', objectToFormData(data), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

const removeProcuration = (data: RemoveProcurationRequest) =>
  client.delete('/meeting/procuration/remove', { data });

export default {
  addProcuration,
  approveProcuration,
  createAgendaPoint,
  createMeeting,
  createMeetingTask,
  deleteAgendaPoint,
  deleteMeeting,
  downloadInvitationAttachment,
  getAttendees,
  getInvitationPreview,
  getInvitationsPreview,
  getMeeting,
  getMeetingsForHomeownerAssociation,
  getMeetingTasks,
  getPossibleBuildingRelations,
  getProcurationHolders,
  getSendReportsPreview,
  inviteAttendee,
  registerAttendee,
  removeProcuration,
  sendInvitations,
  sendReport,
  updateAgendaPoint,
  updateAttendeeInvitationStatus,
  updateAttendeeRemark,
  updateMeeting,
  updateMeetingStatus,
  updateMeetingTask,
  updateProcuration,
};
