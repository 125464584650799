import * as yup from 'yup';
import i18next from 'i18next';

import isPhoneNumberValid from '@/shared/utils/phoneUtils/phoneUtils';

const methodKeys = {
  ALPH_NUM: 'alphaNumeric',
  TEL: 'phoneNumber',
};

const regExp = {
  ALPH_NUM: /^[a-zA-Z0-9]+$/,
  ALPH_NUM_U: /^\w+$/,
};

function alphaNumeric(
  this: yup.StringSchema,
  message?: string,
  options: yup.AlphaNumericOptions = { allowUnderscore: true },
) {
  const pattern = options.allowUnderscore ? regExp.ALPH_NUM_U : regExp.ALPH_NUM;

  return this.matches(pattern, message || i18next.t('errors:shouldBeAlphaNumeric'));
}

function phoneNumber(this: yup.AnyObjectSchema, isMobile: boolean, allowBoth = false) {
  return this.test((value, context) => {
    if (!value?.number) return true;

    const { isValid, errorMessage: message } = isPhoneNumberValid(
      i18next.t,
      value.number,
      value.countryCode,
      isMobile,
      allowBoth,
    );

    if (isValid) return true;

    return context.createError({
      message,
    });
  });
}

yup.addMethod(yup.string, methodKeys.ALPH_NUM, alphaNumeric);
yup.addMethod(yup.object, methodKeys.TEL, phoneNumber);
