import { Form, Formik } from 'formik';
import { TRPCErrorShape } from '@trpc/server/rpc';
import { useRef } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { CreateTeamInput } from '@/types/trpc/team';
import { Nullable } from '~/common/types';

import useAddTeamFormConfig, { AddEditTeamFormikValues } from './useAddEditTeamFormConfig';
import { sentencize } from '../../../shared/utils/helpers';
import { trpc } from '@/config/trpc';
import useToggle from '@/shared/hooks/UseToggle';

import EditTeamFormBody from './AddEditTeamFormBody';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';
import ModalFooter from '@/shared/components/2.0/forms/ModalFooter';

type Props = {
  toggle: ReturnType<typeof useToggle>;
};

const AddTeamModal: React.FC<Props> = ({ toggle }) => {
  const popperContainer = useRef<Nullable<HTMLDivElement>>(null);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { initialValues, validationSchema } = useAddTeamFormConfig();
  const { mutateAsync: createTeamAsync } = trpc.team.create.useMutation();

  const type = t('team');

  const handleAddTeam = async (formData: AddEditTeamFormikValues) => {
    try {
      await createTeamAsync(formData as CreateTeamInput);

      enqueueSnackbar(t('typeSuccessfullyAdded', { type }));
      toggle.hide();
    } catch (error) {
      const { message } = error as TRPCErrorShape;

      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  return (
    <KpcModal size="xl:w-1/2 w-2/3" toggle={toggle} title={sentencize(t('addType', { type }))}>
      <Formik
        className="pb-3"
        onSubmit={handleAddTeam}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <Form>
          <EditTeamFormBody />
          <ModalFooter onCancel={toggle.hide} />
        </Form>
      </Formik>
      <div ref={popperContainer} />
    </KpcModal>
  );
};

export default AddTeamModal;
