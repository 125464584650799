import { Dropdown, IconButton, ListItemDecorator, Menu, MenuButton, MenuItem } from '@mui/joy';
import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

import { Attachment as AttachmentType } from '../types';
import { ContentType } from '@/types/documents';

import {
  checkFileCanBePreviewed,
  formatBytes,
  getFileTypeIconForContentType,
} from '@/shared/utils/helpers';
import downloadFile from '@/shared/utils/fileHelpers';
import { useGetFileUri } from '@/queries/fileManager';
import useToggle from '@/shared/hooks/UseToggle';

import { AngleDownIcon, CancelIcon, DownloadIcon, PreviewIcon } from '@/shared/icons/Icons';
import DocumentPreviewModal from '@/shared/components/documents/Documents/DocumentPreviewModal';

type Props = {
  attachment: AttachmentType | File;
  onRemove?: (fileId: string) => void;
  onDownload?: (file: AttachmentType) => void;
  alwaysAllowRemove?: boolean;
};

const Attachment: React.FC<Props> = ({
  attachment,
  onRemove,
  onDownload,
  alwaysAllowRemove = false,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const isFile = attachment instanceof File;
  const isNylasFile = (attachment as AttachmentType).isInline !== undefined;

  const { uri } = useGetFileUri(
    !isFile && !attachment.fileUri && !isNylasFile ? attachment.id : null,
  );

  const attachmentDetails = useMemo(() => {
    if (isFile) {
      const { name, type, size } = attachment;

      return {
        contentType: type,
        fileName: name,
        fileSize: size,
        id: name,
      };
    }

    return attachment;
  }, [attachment, isFile]);

  const { id, fileName, contentType, fileSize } = attachmentDetails;

  const previewToggle = useToggle();

  const fileToPreview = useMemo(() => {
    if (isFile) {
      return null;
    }

    return {
      ...attachment,
      contentType: attachment.contentType || ContentType.TXT,
      fileUri: attachment.fileUri || uri || '',
    };
  }, [attachment, isFile, uri]);

  const handleDownload = async () => {
    if (!fileToPreview) {
      return;
    }

    if (onDownload) {
      onDownload(fileToPreview);
    } else {
      window.open(fileToPreview.fileUri, '_blank');
    }
  };

  const fileIcon = getFileTypeIconForContentType(contentType) as IconName;

  const hasFileUri = fileToPreview && fileToPreview.fileUri;
  const canPreview = !isFile && checkFileCanBePreviewed(contentType) && hasFileUri;
  const canDownload = !isFile && hasFileUri;
  const canRemove = isFile || alwaysAllowRemove;

  return (
    <div
      key={`file-${id}`}
      className="flex h-12 w-48 items-center justify-between rounded border border-slate-200 bg-slate-50 p-2"
    >
      <div className="flex items-center">
        <FontAwesomeIcon className="mr-2 text-xl" icon={['fad', fileIcon]} />
        <div className="ml-2 mr-2 flex w-[6.25rem] flex-col overflow-hidden">
          <div className="truncate text-ellipsis text-xs">{fileName}</div>
          {fileSize && <span className="text-xxs">{formatBytes(fileSize)}</span>}
        </div>
        <Dropdown open={open} onOpenChange={(_, o) => setOpen(o)}>
          <MenuButton
            slots={{ root: IconButton }}
            slotProps={{ root: { color: 'neutral', variant: 'plain' } }}
          >
            <AngleDownIcon className={open ? 'rotate-180' : ''} />
          </MenuButton>
          <Menu className="z-popperModal" size="sm" placement="bottom-end">
            {canPreview && (
              <MenuItem key={id} onClick={previewToggle.show}>
                <ListItemDecorator>
                  <PreviewIcon />
                </ListItemDecorator>
                <span>{t('preview')}</span>
              </MenuItem>
            )}
            {canDownload && (
              <MenuItem onClick={handleDownload}>
                <ListItemDecorator>
                  <DownloadIcon />
                </ListItemDecorator>
                <span>{t('download')}</span>
              </MenuItem>
            )}
            {onRemove && canRemove && (
              <MenuItem onClick={() => onRemove(id)}>
                <ListItemDecorator>
                  <CancelIcon />
                </ListItemDecorator>
                {t('remove')}
              </MenuItem>
            )}
          </Menu>
        </Dropdown>
      </div>
      {canPreview && (
        <DocumentPreviewModal
          toggle={previewToggle}
          file={fileToPreview}
          onDownload={() => downloadFile(fileToPreview)}
        />
      )}
    </div>
  );
};

export default Attachment;
