import { FormControl, FormHelperText, FormLabel } from '@mui/joy';

export type FieldProps = {
  className?: string;
  error?: string;
  horizontal?: boolean;
  label?: string;
  required?: boolean;
};

const FormField: React.FC<React.PropsWithChildren<FieldProps>> = ({
  children,
  className,
  error,
  horizontal,
  label,
  required = false,
}) => (
  <FormControl
    orientation={horizontal ? 'horizontal' : 'vertical'}
    className={className}
    error={!!error}
  >
    {!!label && <FormLabel required={required}>{label}</FormLabel>}
    {children}
    {!!error && <FormHelperText className="px-1 text-xs">{error}</FormHelperText>}
  </FormControl>
);

export default FormField;
